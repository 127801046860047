import { React, useContext } from "react";

import ThemeContext from "../../utils/theme-context";

import Button from "@mui/material/Button";

const CustomButtom = ({ variant, onClick, label }) => {
  const theme = useContext(ThemeContext);

  return (
    <Button
      sx={{
        margin: "5px 10px",
        textTransform: "capitalize",
        color: theme[0] === "dark" ? "#FCFCFF" : "black",
        border:
          theme[0] === "dark"
            ? " 0.8px solid #FCFCFF"
            : " 0.8px solid rgba(37,37,37)",
      }}
      variant={variant}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default CustomButtom;
