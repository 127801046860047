import { useState, useEffect, useContext } from "react";

import { DataFetcher } from "../../utils/dataFetcher";

import dayjs from "dayjs";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import CustomMultiTextField from "../../components/textfields/multiRow_standard";
import CustomDatePicker from "../dateTimePickers/datePicker";
import { DataGrid } from "@mui/x-data-grid";
import columnsReflections from "../../lib/columnsReflections";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import ThemeContext from "../../utils/theme-context";
import AuthContext from "../../utils/auth-context";

import markets from "../../lib/markets";

import throwMessage from "../../utils/throwMessage";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import Switch from "@mui/material/Switch";

import Tooltip from "@mui/material/Tooltip";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import JoinRightOutlinedIcon from "@mui/icons-material/JoinRightOutlined";

import useWindowDimensions from "../../utils/width";

const NewReflection = ({
  open,
  onClose,
  openReflection,
  setSingleJournalView,
  setOpenJournal,
  setSingleReflectionView,
  setSingleForecastView,
}) => {
  const auth = useContext(AuthContext);
  const theme = useContext(ThemeContext);
  const { width } = useWindowDimensions();
  const [typeOfReview, setTypeOfReview] = useState("");
  const [question_1, setQuestion_1] = useState("");
  const [question_2, setQuestion_2] = useState("");
  const [question_3, setQuestion_3] = useState("");
  const [question_4, setQuestion_4] = useState("");
  const [question_5, setQuestion_5] = useState("");
  const [question_6, setQuestion_6] = useState("");
  const [question_7, setQuestion_7] = useState("");
  const [question_8, setQuestion_8] = useState("");
  const [weekly, setWeekly] = useState(undefined);
  const [monthly, setMonthly] = useState(undefined);
  const [q1, setQ1] = useState(undefined);
  const [q2, setQ2] = useState(undefined);
  const [q3, setQ3] = useState(undefined);
  const [q4, setQ4] = useState(undefined);
  const [hideTypeOf, setHideTypeOf] = useState(false);
  const [changeDate, setChangeDate] = useState(false);
  const [date, setDate] = useState(dayjs(new Date()));
  const [trades, setTrades] = useState([]);

  const [returnPercentPC, setReturnPercentPC] = useState(0);
  const [returnPercentIC, setReturnPercentIC] = useState(0);
  const [returnDollarPersonal, setReturnDollarPersonal] = useState(0);
  const [returnDollarInvestor, setReturnDollarInvestor] = useState(0);

  const [winsPC, setWinsPC] = useState(0);
  const [lossPC, setLossPC] = useState(0);
  const [inProgressPC, setInProgressPC] = useState(0);
  const [winsIC, setWinsIC] = useState(0);
  const [lossIC, setLossIC] = useState(0);
  const [inProgressIC, setInProgressIC] = useState(0);
  const [bePC, setBePC] = useState(0);
  const [beIC, setBeIC] = useState(0);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [missedTrades, setMissedTrades] = useState(false);
  const mode = localStorage.getItem("equity-mode");

  useEffect(() => {
    openReflection && getTrades();
    localStorage.setItem("reflection-saved", false);

    return () => {
      localStorage.removeItem("reflection-saved");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, weekly, monthly, q1, q2, q3, q4, missedTrades, mode]);

  const getTrades = async () => {
    const checkType = () => {
      if (weekly) {
        return "Weekly";
      } else if (monthly) {
        return "Monthly";
      } else if (q1) {
        return "Q1";
      } else if (q2) {
        return "Q2";
      } else if (q3) {
        return "Q3";
      } else if (q4) {
        return "Q4";
      }
    };
    let result;
    result = await DataFetcher("get-trades-to-reflect", {
      typeOfReviewQuery: checkType(),
      date: date,
      missedTrade: missedTrades ? true : false,
    });
    if (result) {
      let _trades = [];
      if (!missedTrades) {
        _trades = result.filter((trades) => trades.id?.includes("live-"));
      } else {
        _trades = result.filter((trades) =>
          trades.missedTrade?.includes("Yes")
        );
      }
      const calTrades = await runCalculations(_trades);
      setTrades(calTrades?.reverse());
    }
  };
  const checkStopLossMutiplyer = (p) => {
    const pairObject = markets.find((m) => m.ticker === p);

    const schema = { multiplier: pairObject?.multiplier };

    return schema;
  };
  const checkTypeOfPair = (p) => {
    const pairObject = markets.find((m) => m.ticker === p);

    const schema = { pipDistance: pairObject?.pipDistance };

    return schema;
  };
  const calculateOutcomeOfSlippage = (
    closePrice,
    openPrice,
    direction,
    pair
  ) => {
    const typeofPair = checkTypeOfPair(pair);

    if (isNaN(closePrice) || closePrice === "") {
      return "In Progress";
    } else {
      if (closePrice === openPrice) {
        return "Breakeven";
      }

      if (direction === "Long") {
        if (closePrice < openPrice) {
          const amountOfPipsLostBy =
            (openPrice - closePrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsLostBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Loss";
          }
        } else if (closePrice > openPrice) {
          const amountOfPipsWonBy =
            (closePrice - openPrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsWonBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Win";
          }
        }
      } else if (direction === "Short") {
        if (closePrice > openPrice) {
          const amountOfPipsLostBy =
            (closePrice - openPrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsLostBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Loss";
          }
        } else if (closePrice < openPrice) {
          const amountOfPipsWonBy =
            (openPrice - closePrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsWonBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Win";
          }
        }
      }
    }
  };
  const workOutWinLoseInPips = (
    outcome,
    direction,
    closePrice,
    openPrice,
    pair
  ) => {
    let pipsWonBy;
    let pipsLostBy;

    const typeofPair = checkTypeOfPair(pair);
    if (outcome === "Breakeven" || outcome === "In Progress") {
      pipsWonBy = 0;
      pipsLostBy = 0;
    }
    // handle longs
    if (direction === "Long" && outcome === "Win") {
      const amountOfPipsWonBy =
        (closePrice - openPrice) / typeofPair.pipDistance;
      pipsWonBy = parseFloat(amountOfPipsWonBy);
      pipsLostBy = 0;
    } else if (direction === "Long" && outcome === "Loss") {
      const amountOfPipsLostBy =
        (openPrice - closePrice) / typeofPair.pipDistance;
      pipsLostBy = parseFloat(amountOfPipsLostBy);
      pipsWonBy = 0;
    }
    // handle Shorts
    if (direction === "Short" && outcome === "Win") {
      const amountOfPipsWonBy =
        (openPrice - closePrice) / typeofPair.pipDistance;
      pipsWonBy = parseFloat(amountOfPipsWonBy);
      pipsLostBy = 0;
    } else if (direction === "Short" && outcome === "Loss") {
      const amountOfPipsLostBy =
        (closePrice - openPrice) / typeofPair.pipDistance;
      pipsLostBy = parseFloat(amountOfPipsLostBy);
      pipsWonBy = 0;
    }

    return { pipsLostBy: pipsLostBy, pipsWonBy: pipsWonBy };
  };
  const calculatePercentageReturn = (
    outcome,
    direction,
    pair,
    pipsWonBy,
    pipsLostBy,
    openPrice,
    risk,
    stopLossPips
  ) => {
    let percent = 0;
    let stop;
    let ESD;
    let DWP = pipsWonBy;
    let DLP = pipsLostBy;

    const multiplier = checkStopLossMutiplyer(pair).multiplier;
    const stopLoss = parseFloat(stopLossPips) / multiplier;
    const pipDistance = checkTypeOfPair(pair).pipDistance;

    if (outcome === "Breakeven" || outcome === "In Progress") {
      percent = 0;
    }

    if (direction === "Long" && outcome === "Win") {
      stop = parseFloat(openPrice) - stopLoss * pipDistance;
      ESD = (parseFloat(openPrice) - stop) / pipDistance;
      const r = ((DWP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }
    if (direction === "Long" && outcome === "Loss") {
      stop = parseFloat(openPrice) - stopLoss * pipDistance;
      ESD = (parseFloat(openPrice) - stop) / pipDistance;
      const r = ((DLP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }
    if (direction === "Short" && outcome === "Loss") {
      stop = stopLoss * pipDistance + parseFloat(openPrice);
      ESD = (stop - parseFloat(openPrice)) / pipDistance;
      const r = ((DLP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }
    if (direction === "Short" && outcome === "Win") {
      stop = stopLoss * pipDistance + parseFloat(openPrice);
      ESD = (stop - parseFloat(openPrice)) / pipDistance;
      const r = ((DWP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }

    return percent;
  };
  const runCalculations = async (_trades) => {
    let trades = _trades;
    let virtualArray = [];

    let totalOutcomesPC = [];
    let totalOutcomesIC = [];

    let returnDollarTotals_Investor_summed;
    let returnDollarTotals_Personal_summed;

    let returnPercentTotalsIC = [];
    let returnDollarTotalsInvestor = [];

    let returnPercentTotalsPC = [];
    let returnDollarTotalsPersonal = [];

    let profitLossDollarInvestor;
    let profitLossPercentageIC;
    let profitLossDollarPersonal;
    let profitLossPercentagePC;
    let allFeesIC = [];
    let allFeesPC = [];

    if (mode === "Personal Capital") {
      trades = trades.filter(
        (trades) => trades?.equityType !== "Investor Capital"
      );
    } else if (mode === "Investor Capital") {
      trades = trades.filter(
        (trades) => trades?.equityType !== "Personal Capital"
      );
    }

    for (let index = 0; index < trades.length; index++) {
      const trade = trades[index];
      const capital = trade.equityType;

      if (capital === "Investor Capital") {
        const outcomeOfSlippageIC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.direction,
          trade.pair
        );
        const workOutWinLoseInPipsIC = workOutWinLoseInPips(
          outcomeOfSlippageIC,
          trade.direction,
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.pair
        );
        const returnPercentIC = calculatePercentageReturn(
          outcomeOfSlippageIC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsIC.pipsWonBy,
          workOutWinLoseInPipsIC.pipsLostBy,
          parseFloat(trade.openPriceIC),
          trade.risk,
          trade.stopLossPipsIC
        );

        const outcome1 = outcomeOfSlippageIC;

        allFeesIC.push(parseFloat(trade.feeIC));

        if (outcome1 === "Win") {
          profitLossPercentageIC = returnPercentIC;
          profitLossPercentageIC = parseFloat(profitLossPercentageIC);
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (outcome1 === "Loss") {
          profitLossPercentageIC = returnPercentIC;
          //convert to negative number
          profitLossPercentageIC = -Math.abs(
            parseFloat(profitLossPercentageIC)
          );
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (outcome1 === "Breakeven" || outcome1 === "In Progress") {
          profitLossPercentageIC = 0;
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        }

        returnPercentTotalsIC.push(profitLossPercentageIC);
        returnDollarTotalsInvestor.push(profitLossDollarInvestor);
        totalOutcomesIC.push(outcomeOfSlippageIC);

        const schema = {
          timezone: trade.timezone,
          id: trade.id,
          _id: trade._id,
          // riskValue: parseFloat(riskValue.toFixed(2)),
          // valuePerPip: parseFloat(valuePerPip.toFixed(2)),
          profitLossDollarInvestor: trade.profitLossDollarInvestor,
          profitLossDollarPersonal: 0,
          profitLossPercentagePC: 0,
          profitLossPercentageIC: parseFloat(profitLossPercentageIC).toFixed(2),
          // rr: riskToRewardDecimal.toFixed(2),
          outcomePC: "",
          outcomeIC: outcomeOfSlippageIC,
          openPricePC: 0,
          closePricePC: 0,
          openPriceIC: trade.openPriceIC,
          closePriceIC: trade.closePriceIC,
          pair: trade.pair,
          direction: trade.direction,
          stopLossPipsPC: 0,
          stopLossPipsIC: trade.stopLossPipsIC,
          entryDate: trade.entryDate,
          entryTime: trade.entryTime,
          exitDate: trade.exitDate,
          exitTime: trade.exitTime,
          feePC: trade.feePC,
          feeIC: trade.feeIC,
          pattern: trade.pattern,
          ro3: trade.ro3,
          duration: "",
          foreCastId: trade.foreCastId,
          min5_before: trade.min5_before,
          min15_before: trade.min15_before,
          hour1_before: trade.hour1_before,
          hour1_after: trade.hour1_after,
          hour4_before: trade.hour4_before,
          hour4_after: trade.hour4_after,
          daily_before: trade.daily_before,
          daily_after: trade.daily_after,
          weekly_before: trade.weekly_before,
          weekly_after: trade.weekly_after,
          journalComplete: trade.journalComplete,
          journalID: trade.journalID,
          entryType: trade.entryType,
          grade: trade.grade,
          highlightedPair: trade.highlightedPair,
          firstEntryOrScaleIn: trade.firstEntryOrScaleIn,
          testId:
            trade.missedTrade === "Yes" ? "All Missed Trades" : trade.testId,
          missedTrade: trade.missedTrade,
          // newTransaction: newTransaction,
          // transanctionNotes: note,
          // transanctionReason: reason,
          returnTotalsPersonal: 0,
          returnTotalsInvestor: returnDollarTotals_Investor_summed?.toFixed(2),
          created: trade.created,
          equityType: trade.equityType,
        };
        virtualArray.push(schema);
      } else if (capital === "Personal Capital") {
        const outcomeOfSlippagePC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.direction,
          trade.pair
        );
        const workOutWinLoseInPipsPC = workOutWinLoseInPips(
          outcomeOfSlippagePC,
          trade.direction,
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.pair
        );
        const returnPercentPC = calculatePercentageReturn(
          outcomeOfSlippagePC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsPC.pipsWonBy,
          workOutWinLoseInPipsPC.pipsLostBy,
          parseFloat(trade.openPricePC),
          trade.risk,
          trade.stopLossPipsPC
        );
        allFeesPC.push(parseFloat(trade.feePC));

        const outcome2 = outcomeOfSlippagePC;

        if (outcome2 === "Win") {
          profitLossPercentagePC = returnPercentPC;
          profitLossPercentagePC = parseFloat(profitLossPercentagePC);
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (outcome2 === "Loss") {
          profitLossPercentagePC = returnPercentPC;
          //convert to negative number
          profitLossPercentagePC = -Math.abs(
            parseFloat(profitLossPercentagePC)
          );
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (outcome2 === "Breakeven" || outcome2 === "In Progress") {
          profitLossPercentagePC = 0;
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        }

        returnPercentTotalsPC.push(profitLossPercentagePC);
        returnDollarTotalsPersonal.push(profitLossDollarPersonal);

        totalOutcomesPC.push(outcomeOfSlippagePC);

        const schema = {
          timezone: trade.timezone,
          id: trade.id,
          _id: trade._id,
          profitLossDollarInvestor: trade.profitLossDollarInvestor,
          profitLossDollarPersonal: trade.profitLossDollarPersonal,
          profitLossPercentagePC: parseFloat(profitLossPercentagePC).toFixed(2),
          profitLossPercentageIC: 0,
          outcomePC: outcomeOfSlippagePC,
          outcomeIC: "",
          openPricePC: trade.openPricePC,
          closePricePC: trade.closePricePC,
          openPriceIC: trade.openPriceIC,
          closePriceIC: trade.closePriceIC,
          pair: trade.pair,
          direction: trade.direction,
          stopLossPipsPC: trade.stopLossPipsPC,
          stopLossPipsIC: trade.stopLossPipsIC,
          entryDate: trade.entryDate,
          entryTime: trade.entryTime,
          exitDate: trade.exitDate,
          exitTime: trade.exitTime,
          feePC: trade.feePC,
          feeIC: trade.feeIC,
          pattern: trade.pattern,
          ro3: trade.ro3,
          duration: "",
          foreCastId: trade.foreCastId,
          min5_before: trade.min5_before,
          min15_before: trade.min15_before,
          hour1_before: trade.hour1_before,
          hour1_after: trade.hour1_after,
          hour4_before: trade.hour4_before,
          hour4_after: trade.hour4_after,
          daily_before: trade.daily_before,
          daily_after: trade.daily_after,
          weekly_before: trade.weekly_before,
          weekly_after: trade.weekly_after,
          journalComplete: trade.journalComplete,
          journalID: trade.journalID,
          entryType: trade.entryType,
          grade: trade.grade,
          highlightedPair: trade.highlightedPair,
          firstEntryOrScaleIn: trade.firstEntryOrScaleIn,
          testId:
            trade.missedTrade === "Yes" ? "All Missed Trades" : trade.testId,
          missedTrade: trade.missedTrade,
          returnTotalsPersonal: returnDollarTotals_Personal_summed?.toFixed(2),
          returnTotalsInvestor: 0,
          created: trade.created,
          equityType: trade.equityType,
          // newTransaction: newTransaction,
          // transanctionNotes: note,
          // transanctionReason: reason,
        };
        virtualArray.push(schema);
      } else if (capital === "Personal & Investor Capital") {
        const outcomeOfSlippageIC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.direction,
          trade.pair
        );
        const outcomeOfSlippagePC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.direction,
          trade.pair
        );

        const workOutWinLoseInPipsIC = workOutWinLoseInPips(
          outcomeOfSlippageIC,
          trade.direction,
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.pair
        );
        const workOutWinLoseInPipsPC = workOutWinLoseInPips(
          outcomeOfSlippageIC,
          trade.direction,
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.pair
        );

        const returnPercentIC = calculatePercentageReturn(
          outcomeOfSlippageIC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsIC.pipsWonBy,
          workOutWinLoseInPipsIC.pipsLostBy,
          parseFloat(trade.openPriceIC),
          trade.risk,
          trade.stopLossPipsIC
        );

        const returnPercentPC = calculatePercentageReturn(
          outcomeOfSlippagePC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsPC.pipsWonBy,
          workOutWinLoseInPipsPC.pipsLostBy,
          parseFloat(trade.openPricePC),
          trade.risk,
          trade.stopLossPipsPC
        );

        allFeesIC.push(parseFloat(trade.feeIC));
        allFeesPC.push(parseFloat(trade.feePC));

        const outcome1 = outcomeOfSlippageIC;
        const outcome2 = outcomeOfSlippagePC;

        if (outcome1 === "Win") {
          profitLossPercentageIC = returnPercentIC;
          profitLossPercentageIC = parseFloat(profitLossPercentageIC);
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (outcome1 === "Loss") {
          profitLossPercentageIC = returnPercentIC;
          //convert to negative number
          profitLossPercentageIC = -Math.abs(
            parseFloat(profitLossPercentageIC)
          );
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (outcome1 === "Breakeven" || outcome1 === "In Progress") {
          profitLossPercentageIC = 0;
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        }

        if (outcome2 === "Win") {
          profitLossPercentagePC = returnPercentPC;
          profitLossPercentagePC = parseFloat(profitLossPercentagePC);
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (outcome2 === "Loss") {
          profitLossPercentagePC = returnPercentPC;
          //convert to negative number
          profitLossPercentagePC = -Math.abs(
            parseFloat(profitLossPercentagePC)
          );
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (outcome2 === "Breakeven" || outcome2 === "In Progress") {
          profitLossPercentagePC = 0;
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        }

        if (outcome2 !== "In Progress" || outcome1 !== "In Progress") {
          returnPercentTotalsIC.push(profitLossPercentageIC);
          returnDollarTotalsInvestor.push(profitLossDollarInvestor);
          returnPercentTotalsPC.push(profitLossPercentagePC);
          returnDollarTotalsPersonal.push(profitLossDollarPersonal);
        }

        totalOutcomesPC.push(outcomeOfSlippagePC);
        totalOutcomesIC.push(outcomeOfSlippageIC);

        const schema = {
          timezone: trade.timezone,
          id: trade.id,
          _id: trade._id,
          profitLossDollarInvestor: trade.profitLossDollarInvestor,
          profitLossDollarPersonal: trade.profitLossDollarPersonal,
          profitLossPercentagePC: parseFloat(profitLossPercentagePC).toFixed(2),
          profitLossPercentageIC: parseFloat(profitLossPercentageIC).toFixed(2),
          outcomePC: outcomeOfSlippagePC,
          outcomeIC: outcomeOfSlippageIC,
          openPricePC: trade.openPricePC,
          closePricePC: trade.closePricePC,
          openPriceIC: trade.openPriceIC,
          closePriceIC: trade.closePriceIC,
          pair: trade.pair,
          direction: trade.direction,
          stopLossPipsPC: trade.stopLossPipsPC,
          stopLossPipsIC: trade.stopLossPipsIC,
          entryDate: trade.entryDate,
          entryTime: trade.entryTime,
          exitDate: trade.exitDate,
          exitTime: trade.exitTime,
          feePC: trade.feePC,
          feeIC: trade.feeIC,
          pattern: trade.pattern,
          ro3: trade.ro3,
          duration: "",
          foreCastId: trade.foreCastId,
          min5_before: trade.min5_before,
          min15_before: trade.min15_before,
          hour1_before: trade.hour1_before,
          hour1_after: trade.hour1_after,
          hour4_before: trade.hour4_before,
          hour4_after: trade.hour4_after,
          daily_before: trade.daily_before,
          daily_after: trade.daily_after,
          weekly_before: trade.weekly_before,
          weekly_after: trade.weekly_after,
          journalComplete: trade.journalComplete,
          journalID: trade.journalID,
          entryType: trade.entryType,
          grade: trade.grade,
          highlightedPair: trade.highlightedPair,
          firstEntryOrScaleIn: trade.firstEntryOrScaleIn,
          testId:
            trade.missedTrade === "Yes" ? "All Missed Trades" : trade.testId,
          missedTrade: trade.missedTrade,
          returnTotalsPersonal: returnDollarTotals_Personal_summed?.toFixed(2),
          returnTotalsInvestor: returnDollarTotals_Investor_summed?.toFixed(2),
          created: trade.created,
          equityType: trade.equityType,
          // newTransaction: newTransaction,
          // transanctionNotes: note,
          // transanctionReason: reason,
        };

        virtualArray.push(schema);
      }
    } // end of for loop

    // --------------------------------- ///

    returnDollarTotals_Investor_summed =
      returnDollarTotalsInvestor.length > 0
        ? returnDollarTotalsInvestor.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;

    let allFees_summed_IC =
      allFeesPC.length > 0
        ? allFeesPC.reduce(function (a, b) {
            return a + b;
          })
        : 0;

    returnDollarTotals_Investor_summed =
      returnDollarTotals_Investor_summed + allFees_summed_IC;

    setReturnDollarInvestor(parseFloat(returnDollarTotals_Investor_summed));

    // --------------------------------- ///

    returnDollarTotals_Personal_summed =
      returnDollarTotalsPersonal.length > 0
        ? returnDollarTotalsPersonal.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;

    let allFees_summed_PC =
      allFeesPC.length > 0
        ? allFeesPC.reduce(function (a, b) {
            return a + b;
          })
        : 0;

    returnDollarTotals_Personal_summed =
      returnDollarTotals_Personal_summed + allFees_summed_PC;

    setReturnDollarPersonal(parseFloat(returnDollarTotals_Personal_summed));

    // --------------------------------- ///

    const winsPC = totalOutcomesPC.filter((res) => res === "Win");
    const lossesPC = totalOutcomesPC.filter((res) => res === "Loss");
    const breakevensPC = totalOutcomesPC.filter((res) => res === "Breakeven");
    const inProgressPC = totalOutcomesPC.filter((res) => res === "In Progress");

    const winsIC = totalOutcomesIC.filter((res) => res === "Win");
    const lossesIC = totalOutcomesIC.filter((res) => res === "Loss");
    const breakevensIC = totalOutcomesIC.filter((res) => res === "Breakeven");
    const inProgressIC = totalOutcomesIC.filter((res) => res === "In Progress");

    setInProgressPC(inProgressPC.length);
    setBePC(breakevensPC.length);
    setLossPC(lossesPC.length);
    setWinsPC(winsPC.length);

    setInProgressIC(inProgressIC.length);
    setBeIC(breakevensIC.length);
    setLossIC(lossesIC.length);
    setWinsIC(winsIC.length);

    // --------------------------------- ///

    const sumTotalProfitLossPercentagePC =
      returnPercentTotalsPC.length > 0
        ? returnPercentTotalsPC.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;

    const sumTotalProfitLossPercentageIC =
      returnPercentTotalsIC.length > 0
        ? returnPercentTotalsIC.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;

    setReturnPercentIC(parseFloat(sumTotalProfitLossPercentageIC));
    setReturnPercentPC(parseFloat(sumTotalProfitLossPercentagePC));
    // --------------------------------- ///

    return virtualArray;
  };
  const handleQ1 = (event) => {
    setQ1(event.target.value);
    setQ2("");
    setQ3("");
    setQ4("");
    setWeekly("");
    setMonthly("");
    setTrades([]);
    setHideTypeOf(true);
  };
  const handleQ2 = (event) => {
    setQ1("");
    setQ2(event.target.value);
    setQ3("");
    setQ4("");
    setWeekly("");
    setMonthly("");
    setTrades([]);
    setHideTypeOf(true);
  };
  const handleQ3 = (event) => {
    setQ1("");
    setQ2("");
    setQ3(event.target.value);
    setQ4("");
    setWeekly("");
    setMonthly("");
    setTrades([]);
    setHideTypeOf(true);
  };
  const handleQ4 = (event) => {
    setQ1("");
    setQ2("");
    setQ3("");
    setQ4(event.target.value);
    setWeekly("");
    setMonthly("");
    setTrades([]);
    setHideTypeOf(true);
  };
  const handleWeekly = (event) => {
    setWeekly(event.target.value);
    setMonthly("");
    setTrades([]);
    setHideTypeOf(true);
    setQ1("");
    setQ2("");
    setQ3("");
    setQ4("");
  };
  const handleMonthly = (event) => {
    setMonthly(event.target.value);
    setWeekly("");
    setTrades([]);
    setHideTypeOf(true);
    setQ1("");
    setQ2("");
    setQ3("");
    setQ4("");
  };
  const handleSave = async () => {
    if (typeOfReview) {
      if (!formSubmitted);
      setFormSubmitted(true);
      const schema = {
        typeOfReview: typeOfReview,
        date: date,
        question_1: question_1,
        question_2: question_2,
        question_3: question_3,
        question_4: question_4,
        question_5: question_5,
        question_6: question_6,
        question_7: question_7,
        question_8: question_8,
      };
      const result = await DataFetcher("new-reflection", schema);
      if (result) {
        localStorage.setItem("reflection-saved", true);
        localStorage.setItem("reflection-to-view", result._id);
        auth.setReflections([...auth.reflections, result]);
        throwMessage("success", "Success");

        setFormSubmitted(false);
      } else {
        throwMessage("error", "Something went wrong");
        setFormSubmitted(false);
      }
    } else {
      throwMessage("warning", "Missing Fields");
    }
  };
  const goBack = () => {
    onClose();
    setTypeOfReview("");
    setQuestion_1("");
    setQuestion_2("");
    setQuestion_3("");
    setQuestion_5("");
    setQuestion_4("");
    setQuestion_6("");
    setQuestion_7("");
    setQuestion_8("");
    setHideTypeOf(false);
    setChangeDate(false);
    setDate(dayjs(new Date()));
    setTrades([]);
    setSingleReflectionView(false);
    setSingleJournalView(false);
    setOpenJournal(false);
    localStorage.removeItem("reflection-saved");
  };
  const setQuestions = (question, response) => {
    switch (question) {
      case "1":
        setQuestion_1(response);
        break;
      case "2":
        setQuestion_2(response);
        break;
      case "3":
        setQuestion_3(response);
        break;
      case "4":
        setQuestion_4(response);
        break;
      case "5":
        setQuestion_5(response);
        break;
      case "6":
        setQuestion_6(response);
        break;
      case "7":
        setQuestion_7(response);
        break;
      case "8":
        setQuestion_8(response);
        break;
      default:
        break;
    }
  };
  const handleMissedTrades = (e) => {
    setMissedTrades(e.target.checked);
  };
  const setColor = () => {
    const mode = localStorage.getItem("equity-mode");
    switch (mode) {
      case "Personal Capital":
        return (
          <SavingsOutlinedIcon
            fontSize="large"
            sx={{ color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF" }}
          />
        );
      case "Investor Capital":
        return (
          <AccountBalanceOutlinedIcon
            fontSize="large"
            sx={{ color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF" }}
          />
        );
      case "Personal & Investor Capital":
        return (
          <JoinRightOutlinedIcon
            sx={{
              color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
              transform: "rotate(180deg)",
              fontSize: "35px",
            }}
          />
        );
      default:
        return (
          <MoreVertIcon
            fontSize="large"
            sx={{ color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF" }}
          />
        );
    }
  };
  const handleMode = () => {
    if (mode === "Personal Capital") {
      localStorage.setItem("equity-mode", "Investor Capital");
      auth.setEquityTypeMode("Investor Capital");
    } else if (mode === "Investor Capital") {
      localStorage.setItem("equity-mode", "Personal & Investor Capital");
      auth.setEquityTypeMode("Personal & Investor Capital");
    } else if (mode === "Personal & Investor Capital") {
      localStorage.setItem("equity-mode", "Personal Capital");
      auth.setEquityTypeMode("Personal Capital");
    }
    runCalculations(
      auth.trades,
      auth.backtestingMode,
      auth.backtestingMode ? localStorage.getItem("backtest-active") : ""
    );
  };
  const setItPercent = (mode, returnPercentPC, returnPercentIC) => {
    const pcic =
      returnPercentPC + returnPercentIC < 0
        ? "#f23645"
        : returnPercentPC + returnPercentIC > 0
        ? "#26a65d"
        : "#ED9E0E";

    const pc =
      returnPercentPC < 0
        ? "#f23645"
        : returnPercentPC > 0
        ? "#26a65d"
        : "#ED9E0E";

    const ic =
      returnPercentIC + returnPercentIC < 0
        ? "#f23645"
        : returnPercentIC + returnPercentIC > 0
        ? "#26a65d"
        : "#ED9E0E";

    if (mode === "Personal Capital") {
      return pc;
    } else if (mode === "Investor Capital") {
      return ic;
    } else {
      return pcic;
    }
  };
  const setItDollar = (mode, returnDollarPersonal, returnDollarInvestor) => {
    const pcic =
      returnDollarPersonal + returnDollarInvestor < 0
        ? "#f23645"
        : returnDollarPersonal + returnDollarInvestor > 0
        ? "#26a65d"
        : "#ED9E0E";

    const pc =
      returnDollarPersonal < 0
        ? "#f23645"
        : returnDollarPersonal > 0
        ? "#26a65d"
        : "#ED9E0E";

    const ic =
      returnDollarInvestor + returnDollarInvestor < 0
        ? "#f23645"
        : returnDollarInvestor + returnDollarInvestor > 0
        ? "#26a65d"
        : "#ED9E0E";

    if (mode === "Personal Capital") {
      return pc;
    } else if (mode === "Investor Capital") {
      return ic;
    } else {
      return pcic;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ maxWidth: "1920px", margin: "0 auto" }}
    >
      <Box
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          width: "100%",
          height: "100%",
          backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          padding: "20px 30px 20px 30px",
          margin: "0 auto",
        }}
      >
        <h3
          onClick={(e) => {
            setTypeOfReview("");
            setChangeDate(true);
            setHideTypeOf(false);
          }}
          style={{
            textAlign: "center",
            fontWeight: "100",
            borderBottom:
              theme[0] === "dark"
                ? " 1px solid #FCFCFF"
                : "1px solid rgba(37,37,37)",
            maxWidth: "750px",
            margin:
              hideTypeOf && trades?.length === 0
                ? "1em auto 5em auto"
                : "1em auto 50px auto",
            padding: "0 0 15px 0",
            cursor: "pointer",
            fontSize: "26px",
          }}
        >
          {" "}
          {weekly
            ? "Weekly"
            : monthly
            ? "Monthly"
            : q1
            ? "Q1 Jan-Mar"
            : q2
            ? "Q2 Apr-Jun"
            : q3
            ? "Q3 Jul-Sep"
            : q4
            ? "Q4 Oct-Dec"
            : "New"}{" "}
          Reflection{" "}
          <span
            onClick={() => {
              setChangeDate(true);
              setDate(dayjs(new Date()));
            }}
          >
            {weekly
              ? moment(date).format("ddd Do MMM")
              : monthly
              ? moment(date).format("MMMM YYYY")
              : q1
              ? ""
              : q2
              ? ""
              : q3
              ? ""
              : q4
              ? ""
              : moment(date).format("ddd Do MMM")}
          </span>{" "}
        </h3>

        <Grid
          container
          // spacing={3}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ minHeight: "100%" }}
        >
          {!hideTypeOf ? (
            <Grid
              item
              xs={width <= 500 ? 12 : 5}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 0 2.8em 0",
              }}
            >
              <FormControl sx={{ display: "flex", alignItems: "center" }}>
                <FormLabel
                  sx={{
                    color: "#ED9E0E",
                    textAlign: "center",
                   
                  }}
                  id=""
                >
                  Reflection Type
                </FormLabel>

                <RadioGroup
                  row
                  aria-labelledby="go-to"
                  defaultValue=""
                  sx={{
                    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    textAlign: "center",
                    marginTop: "0.5em"
                  }}
                  onChange={(e) => {
                    setTypeOfReview(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="Weekly"
                    control={
                      <Radio
                        onChange={handleWeekly}
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      />
                    }
                    label="Weekly"
                  />
                  <FormControlLabel
                    value="Monthly"
                    control={
                      <Radio
                        onChange={handleMonthly}
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      />
                    }
                    label="Monthly"
                  />
                  <RadioGroup
                    row
                    aria-labelledby="go-to"
                    defaultValue=""
                    sx={{
                      color:
                        theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    }}
                    onChange={(e) => {
                      setTypeOfReview(e.target.value);
                    }}
                  >
                    {moment().week() <= 14 && (
                      <FormControlLabel
                        value="Q1"
                        control={
                          <Radio
                            onChange={handleQ1}
                            sx={{
                              color:
                                theme[0] !== "dark"
                                  ? "rgba(40, 42, 46)"
                                  : "#FCFCFF",
                            }}
                          />
                        }
                        label="Q1"
                      />
                    )}

                    {moment().week() >= 14 && moment().week() <= 26 && (
                      <FormControlLabel
                        value="Q2"
                        control={
                          <Radio
                            onChange={handleQ2}
                            sx={{
                              color:
                                theme[0] !== "dark"
                                  ? "rgba(40, 42, 46)"
                                  : "#FCFCFF",
                            }}
                          />
                        }
                        label="Q2"
                      />
                    )}

                    {moment().week() >= 26 && moment().week() <= 40 && (
                      <FormControlLabel
                        value="Q3"
                        control={
                          <Radio
                            onChange={handleQ3}
                            sx={{
                              color:
                                theme[0] !== "dark"
                                  ? "rgba(40, 42, 46)"
                                  : "#FCFCFF",
                            }}
                          />
                        }
                        label="Q3"
                      />
                    )}

                    {moment().week() >= 40 ||
                      (moment().week() === 1 && (
                        <FormControlLabel
                          value="Q4"
                          control={
                            <Radio
                              onChange={handleQ4}
                              sx={{
                                color:
                                  theme[0] !== "dark"
                                    ? "rgba(40, 42, 46)"
                                    : "#FCFCFF",
                              }}
                            />
                          }
                          label="Q4"
                        />
                      ))}
                  </RadioGroup>
                </RadioGroup>
              </FormControl>

              {/* <CustomMultiTextField
                size="small"
                rows={3}
                variant="standard"
                // value={higherTimeframeName}
                // label={higherTimeframeName ? "" : "Enter Timeframe"}
                onChange={(e) => {
                  // setHigherTimeframeName(e.target.value);
                }}
                fontSize="20px"
                // disableUnderline={true}
              /> */}
            </Grid>
          ) : null}

          {trades?.length === 0 && typeOfReview && hideTypeOf && (
            <Grid
              item
              xs={12}
              style={{ margin: "-4em auto 2em auto", textAlign: "center" }}
            >
              <p
                style={{
                  color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  fontSize: "0.7em",
                }}
              >
                {!missedTrades
                  ? "You didn't take any trades this"
                  : "No missed trades this"}{" "}
                {weekly
                  ? "week"
                  : monthly
                  ? "month"
                  : q1
                  ? "quarter"
                  : q2
                  ? "quarter"
                  : q3
                  ? "quarter"
                  : q4
                  ? "quarter"
                  : ""}{" "}
                on {mode}
              </p>
            </Grid>
          )}

          {changeDate && typeOfReview ? (
            <Grid item xs={2} style={{ margin: "0 0 2em 0" }}>
              <CustomDatePicker
                size="small"
                label="Calendar Event Date"
                value={date}
                onChange={(newValue) => {
                  if (moment(newValue.$d).isAfter(moment(), "day")) {
                    setDate(dayjs());
                    throwMessage("warning", "Date is in the future");
                  } else {
                    setChangeDate(false);
                    setHideTypeOf(true);
                    setDate(newValue.$d);
                  }
                }}
              />
            </Grid>
          ) : null}

          {trades?.length > 0 ? (
            <>
              <Grid
                style={{
                  margin: "0 auto 6em auto",
                  height: "500px",
                  width: "95%",
                }}
              >
                <Grid
                  item
                  xs={width <= 500 ? 12 : 8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    flexDirection:  width <= 500 ? "column" : "row",
                    margin: "-1em auto 2em auto",
                    fontSize: "0.75em",
                  }}
                >
                  <p style={{ color: "#26a65d" }}>
                    <span
                      style={{
                        color:
                          theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      }}
                    >
                      Wins:
                    </span>{" "}
                    {mode === "Personal Capital"
                      ? winsPC
                      : mode === "Investor Capital"
                      ? winsIC
                      : winsPC + winsIC}
                  </p>
                  <p style={{ color: "#f23645" }}>
                    <span
                      style={{
                        color:
                          theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      }}
                    >
                      Losses:
                    </span>{" "}
                    {mode === "Personal Capital"
                      ? lossPC
                      : mode === "Investor Capital"
                      ? lossIC
                      : lossPC + lossIC}
                  </p>
                  <p style={{ color: "#ED9E0E" }}>
                    <span
                      style={{
                        color:
                          theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      }}
                    >
                      Breakevens:
                    </span>{" "}
                    {mode === "Personal Capital"
                      ? bePC
                      : mode === "Investor Capital"
                      ? beIC
                      : bePC + beIC}
                  </p>
                  <p
                    style={{
                      color:
                        theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    }}
                  >
                    <span>In Progress:</span>{" "}
                    {mode === "Personal Capital"
                      ? inProgressPC
                      : mode === "Investor Capital"
                      ? inProgressIC
                      : inProgressPC + inProgressIC}
                  </p>{" "}
                  {mode === "Personal & Investor Capital" ? (
                    <p>
                      <span
                        style={{
                          color:
                            returnPercentPC < 0
                              ? "#f23645"
                              : returnPercentPC > 0
                              ? "#26a65d"
                              : "#ED9E0E",
                        }}
                      >
                        {Math.abs(returnPercentPC.toFixed(2))} %
                      </span>{" "}
                      /{" "}
                      <span
                        style={{
                          color:
                            returnPercentIC < 0
                              ? "#f23645"
                              : returnPercentIC > 0
                              ? "#26a65d"
                              : "#ED9E0E",
                        }}
                      >
                        {Math.abs(returnPercentIC.toFixed(2))} %
                      </span>
                    </p>
                  ) : (
                    <p
                      style={{
                        color: setItPercent(
                          mode,
                          returnPercentPC,
                          returnPercentIC
                        ),
                      }}
                    >
                      {mode === "Personal Capital"
                        ? `${Math.abs(returnPercentPC.toFixed(2))} %`
                        : mode === "Investor Capital"
                        ? `${Math.abs(returnPercentIC.toFixed(2))} %`
                        : ""}
                    </p>
                  )}
                  {mode === "Personal & Investor Capital" ? (
                    <p>
                      <span
                        style={{
                          color:
                            returnDollarPersonal < 0
                              ? "#f23645"
                              : returnDollarPersonal > 0
                              ? "#26a65d"
                              : "#ED9E0E",
                        }}
                      >
                        £ {Math.abs(returnDollarPersonal.toFixed(2))}
                      </span>{" "}
                      /{" "}
                      <span
                        style={{
                          color:
                            returnDollarInvestor < 0
                              ? "#f23645"
                              : returnDollarInvestor > 0
                              ? "#26a65d"
                              : "#ED9E0E",
                        }}
                      >
                        $ {Math.abs(returnDollarInvestor.toFixed(2))}
                      </span>
                    </p>
                  ) : (
                    <p
                      style={{
                        color: setItDollar(
                          mode,
                          returnDollarPersonal,
                          returnDollarInvestor
                        ),
                      }}
                    >
                      {mode === "Personal Capital"
                        ? `£ ${Math.abs(returnDollarPersonal.toFixed(2))}`
                        : mode === "Investor Capital"
                        ? `$ ${Math.abs(returnDollarInvestor.toFixed(2))}`
                        : ""}
                    </p>
                  )}
                </Grid>

                <DataGrid
                  sx={{
                    width: "100%",
                    height: "500px",
                    "& .MuiSvgIcon-root": {
                      color: theme[0] === "dark" ? "#FCFCFF" : "black",
                    },

                    backgroundColor:
                      theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    color: theme[0] === "dark" ? "#FCFCFF" : "black",
                    "& .MuiDataGrid-cell": {
                      cursor: "pointer",
                    },
                    "& .MuiTablePagination-displayedRows": {
                      color: theme[0] === "dark" ? "#FCFCFF" : "black",
                    },
                    "& .MuiTablePagination-actions": {
                      color: theme[0] === "dark" ? "#FCFCFF" : "black",
                    },
                  }}
                  rows={trades}
                  columns={columnsReflections(
                    setSingleJournalView,
                    setSingleReflectionView,
                    setOpenJournal,
                    setSingleForecastView,
                    onClose,
                    theme
                  )}
                  autoPageSize
                  rowHeight={53}
                  // disableSelectionOnClick
                />
              </Grid>
            </>
          ) : null}

          {/* <Grid
            item
            xs={5}
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <p>
              Look How much backtesting did I do{" "}
              {weekly
                ? "Week"
                : monthly
                ? "Month"
                : q1
                ? "quarter"
                : q2
                ? "quarter"
                : q3
                ? "quarter"
                : q4
                ? "quarter"
                : ""}
              ?
            </p>
          </Grid> */}

          <Grid
            container
            spacing={7}
            justifyContent="space-evenly"
            alignItems="center"
            sx={{ minHeight: "100%", fontSize: "16px" }}
          >
            {weekly || monthly || q1 || q2 || q3 || q4 ? (
              <Grid
                item
                xs={12}
                style={{
                  marginTop: width <= 500 ? "18em" : "-2em",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FormControlLabel
                  sx={{
                    color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  }}
                  control={<Switch />}
                  label={missedTrades ? "Missed Trades" : "Live Trades"}
                  checked={missedTrades}
                  onChange={handleMissedTrades}
                  name={"Missed Trades"}
                />
                <Tooltip
                  title={mode}
                  fontSize="large"
                  sx={{
                    color: theme[0] === "dark" ? "#FCFCFF" : "black",
                    cursor: "pointer",
                  }}
                >
                  <IconButton
                    sx={{
                      color:
                        theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      margin: "0 0.5em 0 0.5em",
                    }}
                    onClick={handleMode}
                  >
                    {setColor()}
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : null}

            <Grid item xs={5}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  borderBottom:
                    theme[0] === "dark"
                      ? " 1px solid #FCFCFF"
                      : "1px solid rgba(37,37,37)",
                  paddingBottom: "10px",
                  width: width <= 500 ? "100%" : "70%",
                  margin: "0 auto 23px auto",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                Things I will do more of next{" "}
                {weekly
                  ? "week"
                  : monthly
                  ? "month"
                  : q1
                  ? "quarter"
                  : q2
                  ? "quarter"
                  : q3
                  ? "quarter"
                  : q4
                  ? "quarter"
                  : ""}
              </p>
              <CustomMultiTextField
                size="small"
                rows={5}
                variant="standard"
                value={question_1}
                onChange={(e) => setQuestions("1", e.target.value)}
                disableUnderline={true}
                fontSize={"14px"}
              />
            </Grid>
            <Grid item xs={5}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  borderBottom:
                    theme[0] === "dark"
                      ? " 1px solid #FCFCFF"
                      : "1px solid rgba(37,37,37)",
                  paddingBottom: "10px",
                  width: width <= 500 ? "100%" : "70%",
                  margin: "0 auto 23px auto",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                Things I will do less of next{" "}
                {weekly
                  ? "week"
                  : monthly
                  ? "month"
                  : q1
                  ? "quarter"
                  : q2
                  ? "quarter"
                  : q3
                  ? "quarter"
                  : q4
                  ? "quarter"
                  : ""}
              </p>
              <CustomMultiTextField
                size="small"
                rows={5}
                variant="standard"
                value={question_2}
                onChange={(e) => setQuestions("2", e.target.value)}
                disableUnderline={true}
                fontSize={"14px"}
              />
            </Grid>

            <Grid item xs={5} style={{ marginTop: "1em" }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  borderBottom:
                    theme[0] === "dark"
                      ? " 1px solid #FCFCFF"
                      : "1px solid rgba(37,37,37)",
                  paddingBottom: "10px",
                  width: width <= 500 ? "100%" : "70%",
                  margin: "0 auto 23px auto",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                What are the negatives/cons about the trades I took this{" "}
                {weekly
                  ? "week"
                  : monthly
                  ? "month"
                  : q1
                  ? "quarter"
                  : q2
                  ? "quarter"
                  : q3
                  ? "quarter"
                  : q4
                  ? "quarter"
                  : ""}
              </p>
              <CustomMultiTextField
                size="small"
                rows={5}
                variant="standard"
                value={question_3}
                onChange={(e) => setQuestions("3", e.target.value)}
                disableUnderline={true}
                fontSize={"14px"}
              />
            </Grid>
            <Grid item xs={5} style={{ marginTop: "1em" }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  borderBottom:
                    theme[0] === "dark"
                      ? " 1px solid #FCFCFF"
                      : "1px solid rgba(37,37,37)",
                  paddingBottom: "10px",
                  width: width <= 500 ? "100%" : "70%",
                  margin: "0 auto 23px auto",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                What are the positives/pros about the trades I took this{" "}
                {weekly
                  ? "week"
                  : monthly
                  ? "month"
                  : q1
                  ? "quarter"
                  : q2
                  ? "quarter"
                  : q3
                  ? "quarter"
                  : q4
                  ? "quarter"
                  : ""}
              </p>
              <CustomMultiTextField
                size="small"
                rows={5}
                variant="standard"
                value={question_4}
                onChange={(e) => setQuestions("4", e.target.value)}
                disableUnderline={true}
                fontSize={"14px"}
              />
            </Grid>
            <Grid item xs={5} style={{ marginTop: "1em" }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  borderBottom:
                    theme[0] === "dark"
                      ? " 1px solid #FCFCFF"
                      : "1px solid rgba(37,37,37)",
                  paddingBottom: "10px",
                  width: width <= 500 ? "100%" : "70%",
                  margin: "0 auto 23px auto",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                How do I feel about my work ethic? Do my actions align with my
                aspirations and goals?
              </p>
              <CustomMultiTextField
                size="small"
                rows={5}
                variant="standard"
                value={question_5}
                onChange={(e) => setQuestions("5", e.target.value)}
                disableUnderline={true}
                fontSize={"14px"}
              />
            </Grid>
            <Grid item xs={5} style={{ marginTop: "1em" }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  borderBottom:
                    theme[0] === "dark"
                      ? " 1px solid #FCFCFF"
                      : "1px solid rgba(37,37,37)",
                  paddingBottom: "10px",
                  width: width <= 500 ? "100%" : "70%",
                  margin: "0 auto 23px auto",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                How do I feel about myself this{" "}
                {weekly
                  ? "week"
                  : monthly
                  ? "month"
                  : q1
                  ? "quarter"
                  : q2
                  ? "quarter"
                  : q3
                  ? "quarter"
                  : q4
                  ? "quarter"
                  : ""}
              </p>
              <CustomMultiTextField
                size="small"
                rows={5}
                variant="standard"
                value={question_6}
                onChange={(e) => setQuestions("6", e.target.value)}
                disableUnderline={true}
                fontSize={"14px"}
              />
            </Grid>

            <Grid item xs={5} style={{ marginTop: "1em" }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  borderBottom:
                    theme[0] === "dark"
                      ? " 1px solid #FCFCFF"
                      : "1px solid rgba(37,37,37)",
                  paddingBottom: "10px",
                  width: width <= 500 ? "100%" : "70%",
                  margin: "0 auto 23px auto",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                How were my emotions when I was in trades? How were my emotions
                when/if I missed trades
              </p>
              <CustomMultiTextField
                size="small"
                rows={5}
                variant="standard"
                value={question_7}
                onChange={(e) => setQuestions("7", e.target.value)}
                disableUnderline={true}
                fontSize={"14px"}
              />
            </Grid>
            <Grid item xs={5} style={{ marginTop: "1em" }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  borderBottom:
                    theme[0] === "dark"
                      ? " 1px solid #FCFCFF"
                      : "1px solid rgba(37,37,37)",
                  paddingBottom: "10px",
                  width: width <= 500 ? "100%" : "70%",
                  margin: "0 auto 23px auto",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                Anything else I want to add (other areas of my life, things I am
                grateful for etc.)
              </p>
              <CustomMultiTextField
                size="small"
                rows={5}
                variant="standard"
                value={question_8}
                onChange={(e) => setQuestions("8", e.target.value)}
                disableUnderline={true}
                fontSize={"14px"}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={width <= 500 ? 6 : 2}
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "0 0 1em 0",
            }}
          >
            <Tooltip title="Close">
              <CloseIcon
                sx={{
                  color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  cursor: "pointer",
                  margin: "0 -5px 0 0",
                }}
                fontSize="large"
                onClick={() => goBack()}
              />
            </Tooltip>

            <Tooltip title="Save">
              <SaveIcon
                sx={{
                  color: formSubmitted ? "lightGrey" : "#26a65d",
                  cursor: "pointer",
                  margin: "0 0 0 -5px",
                }}
                fontSize="large"
                onClick={() => {
                  handleSave();
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default NewReflection;
