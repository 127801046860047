import React from "react";
import "./App.css";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";
import { theme } from "../src/infrastructure/theme";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en-gb";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <App />
      </LocalizationProvider>
    </ThemeProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
