import { useEffect, useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import ThemeContext from "../utils/theme-context";

const darkTheme = `RemoteCalc({"Url":"https://www.cashbackforex.com", "TopPaneStyle":"YmFja2dyb3VuZDogcmdiYSg0MCwgNDIsIDQ2KTsgY29sb3I6IHdoaXRlOyBib3JkZXI6IG5vbmU7","BottomPaneStyle":"YmFja2dyb3VuZDogcmdiYSg0MCwgNDIsIDQ2KTsgYm9yZGVyOiBzb2xpZCAwcHggIzJhMmUzOTsgY29sb3I6IHdoaXRlOyBwYWRkaW5nLWJvdHRvbToxLjVlbTs=","ButtonStyle":"YmFja2dyb3VuZDogcmdiYSg0MCwgNDIsIDQ2KTsgY29sb3I6IHdoaXRlOyBib3JkZXItcmFkaXVzOiAyMHB4O2JvcmRlcjogMXB4IHNvbGlkIHdoaXRlOw==","TitleStyle":"dGV4dC1hbGlnbjogbGVmdDsgZm9udC1zaXplOiA0MHB4OyBmb250LXdlaWdodDogNTAwOw==","TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYWFhYTsgYm9yZGVyLXJhZGl1czogMHB4Ow==","ContainerWidth":"665","HighlightColor":"rgba(0,0,0,1.0)","IsDisplayTitle":false,"IsShowChartLinks":false,"IsShowEmbedButton":false,"CompactType":"large","Calculator":"position-size-calculator","ContainerId":"position-size-calculator-382848"});`;


const lightTheme = `RemoteCalc({"Url":"https://www.cashbackforex.com", "TopPaneStyle":"YmFja2dyb3VuZDogI0ZDRkNGRjsgY29sb3I6IGJsYWNrOyBib3JkZXI6IG5vbmU7","BottomPaneStyle":"YmFja2dyb3VuZDogI0ZDRkNGRjsgYm9yZGVyOiBub25lOyBjb2xvcjogYmxhY2s7IHBhZGRpbmctYm90dG9tOjEuNWVtOw==","ButtonStyle":"YmFja2dyb3VuZDogI0ZDRkNGRjsgY29sb3I6IGJsYWNrOyBib3JkZXItcmFkaXVzOiAyMHB4O2JvcmRlcjogMXB4IHNvbGlkIHdoaXRlOw==","TitleStyle":"dGV4dC1hbGlnbjogbGVmdDsgZm9udC1zaXplOiA0MHB4OyBmb250LXdlaWdodDogNTAwOw==","TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYWFhYTsgYm9yZGVyLXJhZGl1czogMHB4Ow==","ContainerWidth":"665","HighlightColor":"rgba(0,0,0,1.0)","IsDisplayTitle":false,"IsShowChartLinks":false,"IsShowEmbedButton":false,"CompactType":"large","Calculator":"position-size-calculator","ContainerId":"position-size-calculator-372820"});`;

const PositionSizeCalculator = ({ onClose }) => {
  const theme = useContext(ThemeContext);

  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");

    // Set the script source to the remote-widgets.js file
    script.src =
      "https://www.cashbackforex.com/Content/remote/remote-widgets.js";
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Load the RemoteCalc script after the remote-widgets.js has loaded
    script.onload = () => {
      const remoteCalcScript = document.createElement("script");
      // lightTheme
      remoteCalcScript.text = theme[0] === "dark" ? darkTheme : lightTheme;
      // Append the RemoteCalc script to the document body
      document.body.appendChild(remoteCalcScript);
    };

    // Cleanup: Remove the script elements when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // The empty dependency array ensures that this effect runs once on component mount
  function handleCal() {
    onClose();
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100%",
        backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          width: "44%",
          margin: 'auto'
        }}
      >
        <div
          className="calculator"
          id={`position-size-calculator-${
            theme[0] === "dark" ? "382848" : "372820"
          }`}
        ></div>
        <div
          style={{
            width: "100%",
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Close" fontSize="large">
            <CloseIcon
              sx={{
                color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                cursor: "pointer",
                textAlign: "center",
                margin: "20px auto 1em auto",
              }}
              onClick={handleCal}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default PositionSizeCalculator;
