import { useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./utils/auth-context";
import { DataFetcher } from "./utils/dataFetcher";

export const ProtectedRoute = ({ authCheck, children }) => {
  const effectRan = useRef(false);

  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const getUserData = async () => {
    auth.setLoading(true);
    const response = await DataFetcher("run-all");
    auth.setLoading(false);
    auth.setTrades(response.trades);
    auth.setBacktests(response.backtests);
    auth.setReflections(response.reflections);
    auth.setForecasts(response.forecasts);
    auth.setForecastsBacktesting(response.forecastsBacktesting);
    auth.setJournals(response.journals);
    auth.setJournalsBacktesting(response.journalsBacktesting);

    auth.setTheme(
      localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"
    );
    auth.setDefaultValue(
      auth.backtestingMode &&
        localStorage.getItem("calendar-view-mode") === "Reflections"
        ? "All"
        : localStorage.getItem("calendar-view-mode")
    );
    auth.setBacktestingMode(
      localStorage.getItem("backtest-mode") === "true" ? true : false
    );

    auth.setInProgressMode(
      localStorage.getItem("inprogress-mode") === "true" ? true : false
    );

    auth.setViewMode(localStorage.getItem("view-mode"));

    localStorage.removeItem("trade-to-view");
    localStorage.removeItem("forecast-to-view");
    localStorage.removeItem("journal-portal");
    localStorage.removeItem("reflection-to-view");
    localStorage.removeItem("reflection-saved");
    localStorage.removeItem("from-tracker");
    localStorage.removeItem("view-date");
  };

  useEffect(() => {
    let intervalIDApiCall;
    async function fetchData() {
      const auth_result = await authCheck();
      if (!auth_result) {
        navigate("/log-in");
      } else {
        await getUserData();
      }
    }

    if (effectRan.current === false) {
      fetchData();
      return () => {
        clearInterval(intervalIDApiCall);
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};
