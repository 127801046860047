import { React, useContext, useState } from "react";

import ThemeContext from "../../utils/theme-context";
import AuthContext from "../../utils/auth-context";
import { DataFetcher } from "../../utils/dataFetcher";

import moment from "moment";
import dayjs from "dayjs";

import CustomSingleTextField from "../textfields/singleRow";
import CustomMultiTextField from "../textfields/multiRow";
import CustomDatePicker from "../dateTimePickers/datePicker";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import markets from "../../lib/markets";

import SaveIcon from "@mui/icons-material/Save";

import Tooltip from "@mui/material/Tooltip";

const Backtests = ({
  open,
  onClose,
  setAllbackTestSchemas,
  handleArchivingTest,
}) => {
  const auth = useContext(AuthContext);
  const throwMessage = auth?.throwMessage;

  const theme = useContext(ThemeContext);
  const [backtestPair, setBacktestPair] = useState("");
  const [startTest, setStartTest] = useState(dayjs(new Date()));
  const [endTest, setEndTest] = useState(dayjs(new Date()));
  const [notes, setNotes] = useState("");
  const [testId, setTestId] = useState("");

  const [formSubmitted, setFormSubmitted] = useState(false);

  const submitNewTest = async (handleCloseBacktest) => {
    if (testId && backtestPair && notes) {
      if (!formSubmitted);
      setFormSubmitted(true);
      const schema = {
        testId: testId,
        pair: backtestPair,
        startDate: startTest,
        exitDate: endTest,
        notes: notes,
        duration: moment(endTest).diff(moment(startTest), "weeks"),
      };
      const result = await DataFetcher("new-backtest", schema);
      if (result) {
        if (auth.backtests.length >= 3) {
          await handleArchivingTest({ _id: auth.backtests[2]._id });
          var foundIndex = auth.backtests.findIndex(
            (x) => x._id === auth.backtests[2]._id
          );
          auth.backtests[foundIndex].archived = "true";
        }
        setAllbackTestSchemas([...auth.backtests, result]);
        auth.setBacktests([...auth.backtests, result]);

        setBacktestPair("");
        setStartTest(dayjs(new Date()));
        setEndTest(dayjs(new Date()));
        setNotes("");
        setTestId("");
        handleCloseBacktest();
        setFormSubmitted(false);
        throwMessage("success", "Success");
      } else {
        throwMessage("error", "Something went wrong");
        setFormSubmitted(false);
      }
    } else {
      throwMessage("warning", "Missing Fields");
    }
  };

  const style = { color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)" };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "rgba(40, 42, 46)",
          width: "60%",
          maxWidth: "700px",
          maxHeight: "700px",
          backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          borderRadius: "10px",
          border:
            theme[0] !== "dark"
              ? "1px solid rgba(37,37,37)"
              : " 1px solid #FCFCFF",
          p: 4,
          outline: "none",
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
          >
            <h3
              style={{
                textAlign: "center",
                fontWeight: "200",
                borderBottom:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid rgba(37,37,37)",
                width: "400px",
                margin: "0 auto 20px auto",
                padding: "0 0 10px 0",
              }}
            >
              New Backtest
            </h3>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth size={"small"}>
              <InputLabel
                sx={{
                  color:
                    theme[0] === "dark"
                      ? "#FCFCFF !important"
                      : "rgba(37,37,37) !important",
                  borderOutline:
                    theme[0] === "dark"
                      ? "#FCFCFF !important"
                      : "rgba(37,37,37) !important",
                }}
              >
                Instrument
              </InputLabel>

              <Select
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor:
                        theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      maxHeight: "450px",

                      "& .MuiMenuItem-root": {
                        padding: 1.5,
                        justifyContent: "center",
                      },
                    },
                  },
                }}
                defaultValue=""
                fullWidth
                sx={{
                  ".MuiSvgIcon-root": {
                    fill: theme[0] === "light" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  },
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",

                  "& fieldset": {
                    // - The <fieldset> inside the Input-root

                    borderColor:
                      theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border
                  },
                  "&:hover fieldset": {
                    borderColor:
                      theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor:
                      theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                  },

                  input: {
                    margin: "0",
                    color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  },
                }}
                value={backtestPair}
                label="Instrument"
                onChange={(event) => {
                  setBacktestPair(event.target.value);
                }}
              >
                {markets.map((m) => {
                  if (m.label === "Forex") {
                    return (
                      <ListSubheader
                        key={m.label}
                        sx={{
                          textAlign: "center",
                          bgcolor:
                            theme[0] === "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          color: "#ED9E0E",
                          fontSize: "0.6em",
                        }}
                      >
                        Forex
                      </ListSubheader>
                    );
                  } else if (m.label === "Indices") {
                    return (
                      <ListSubheader
                        key={m.label}
                        sx={{
                          textAlign: "center",
                          bgcolor:
                            theme[0] === "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          color: "#ED9E0E",
                          fontSize: "0.6em",
                        }}
                      >
                        Indices
                      </ListSubheader>
                    );
                  } else if (m.label === "Commodities") {
                    return (
                      <ListSubheader
                        key={m.label}
                        sx={{
                          textAlign: "center",
                          bgcolor:
                            theme[0] === "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          color: "#ED9E0E",
                          fontSize: "0.6em",
                        }}
                      >
                        Commodities
                      </ListSubheader>
                    );
                  } else if (m.label === "Crypto") {
                    return (
                      <ListSubheader
                        key={m.label}
                        sx={{
                          textAlign: "center",
                          bgcolor:
                            theme[0] === "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          color: "#ED9E0E",
                          fontSize: "0.6em",
                        }}
                      >
                        Crypto
                      </ListSubheader>
                    );
                  } else {
                    return (
                      <MenuItem key={m.label} sx={style} value={m.ticker}>
                        {m.label}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <CustomSingleTextField
              size="small"
              value={testId}
              label="Test Name"
              onChange={(e) => setTestId(e.target.value)}
            />
          </Grid>

          <Grid item xs={5}>
            <CustomDatePicker
              size="small"
              label="Start Date"
              value={startTest}
              onChange={(newValue) => setStartTest(newValue.$d)}
            />
          </Grid>
          <Grid item xs={2}>
            <p
              style={{
                fontSize: "14px",
                textAlign: "center",
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
              }}
            >
              {moment(endTest).diff(moment(startTest), "weeks")}{" "}
              {moment(endTest).diff(moment(startTest), "weeks") === 1
                ? "Week"
                : "Weeks"}
            </p>
          </Grid>
          <Grid item xs={5}>
            <CustomDatePicker
              size="small"
              label="End Date"
              value={endTest}
              onChange={(newValue) => setEndTest(newValue.$d)}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomMultiTextField
              size="small"
              rows={3}
              value={notes}
              label="Notes"
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            height: "45px",
            margin: "1.5em 0 -15px 0",
          }}
        >
          <Tooltip title="Save">
            <SaveIcon
              sx={{
                color: formSubmitted ? "lightGrey" : "#26a65d",
                cursor: "pointer",
              }}
              fontSize="large"
              onClick={() => submitNewTest(onClose)}
            />
          </Tooltip>
        </Grid>
      </Box>
    </Modal>
  );
};

export default Backtests;
