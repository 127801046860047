import { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import AuthContext from "../../utils/auth-context";
import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Weekly = ({ calculateFields, theme, years, selected, year }) => {
  const auth = useContext(AuthContext);
  const backTestId = localStorage.getItem("backtest-active");
  const backtestingMode = auth.backtestingMode;
  const [percentReturn, setPercentReturn] = useState([]);
  const [moneyReturn, setMoneyReturn] = useState([]);
  const mode = localStorage.getItem("equity-mode");
  const backtestActive = localStorage.getItem("backtest-active");

  useEffect(() => {
    workOutCurvePoints(!years ? year : years);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, mode, backtestActive]);

  const all = mode === "Personal & Investor Capital";
  const personal = mode === "Personal Capital";
  const investor = mode === "Investor Capital";

  const workOutCurvePoints = async (years) => {
    let trades = await calculateFields(
      auth.trades,
      backtestingMode,
      backtestingMode ? backTestId : "",
      false,
      true
    );

    if (backtestingMode) {
      if (backTestId === "Missed Trades") {
        trades = trades.filter((t) => t.missedTrade === "Yes");
      } else {
        trades = trades.filter((t) => t.testId === backTestId);
      }
    }

    if (trades.length > 0);

    trades = trades.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    );
    trades = trades.filter(
      (t) => moment(t.entryDate, "DD/MM/YYYY").format("Y") === years
    );

    const organised = [];

    trades.forEach((trade) => {
      const week = moment(trade.entryDate, "DD/MM/YYYY").format("W");
      for (let index = 0; index <= 52; index++) {
        if (parseInt(week) === index + 1) {
          organised.push(Object.assign(trade, { week: parseInt(week) }));
        }
      }
    });

    trades = organised;

    const week1 = [];
    const week2 = [];
    const week3 = [];
    const week4 = [];
    const week5 = [];
    const week6 = [];
    const week7 = [];
    const week8 = [];
    const week9 = [];
    const week10 = [];
    const week11 = [];
    const week12 = [];
    const week13 = [];
    const week14 = [];
    const week15 = [];
    const week16 = [];
    const week17 = [];
    const week18 = [];
    const week19 = [];
    const week20 = [];
    const week21 = [];
    const week22 = [];
    const week23 = [];
    const week24 = [];
    const week25 = [];
    const week26 = [];
    const week27 = [];
    const week28 = [];
    const week29 = [];
    const week30 = [];
    const week31 = [];
    const week32 = [];
    const week33 = [];
    const week34 = [];
    const week35 = [];
    const week36 = [];
    const week37 = [];
    const week38 = [];
    const week39 = [];
    const week40 = [];
    const week41 = [];
    const week42 = [];
    const week43 = [];
    const week44 = [];
    const week45 = [];
    const week46 = [];
    const week47 = [];
    const week48 = [];
    const week49 = [];
    const week50 = [];
    const week51 = [];
    const week52 = [];

    trades.forEach((trade) => {
      if (trade.week === 1) {
        week1.push(trade);
      } else if (trade.week === 2) {
        week2.push(trade);
      } else if (trade.week === 3) {
        week3.push(trade);
      } else if (trade.week === 4) {
        week4.push(trade);
      } else if (trade.week === 5) {
        week5.push(trade);
      } else if (trade.week === 6) {
        week6.push(trade);
      } else if (trade.week === 7) {
        week7.push(trade);
      } else if (trade.week === 8) {
        week8.push(trade);
      } else if (trade.week === 9) {
        week9.push(trade);
      } else if (trade.week === 10) {
        week10.push(trade);
      } else if (trade.week === 11) {
        week11.push(trade);
      } else if (trade.week === 12) {
        week12.push(trade);
      } else if (trade.week === 13) {
        week13.push(trade);
      } else if (trade.week === 14) {
        week14.push(trade);
      } else if (trade.week === 15) {
        week15.push(trade);
      } else if (trade.week === 16) {
        week16.push(trade);
      } else if (trade.week === 17) {
        week17.push(trade);
      } else if (trade.week === 18) {
        week18.push(trade);
      } else if (trade.week === 19) {
        week19.push(trade);
      } else if (trade.week === 20) {
        week20.push(trade);
      } else if (trade.week === 21) {
        week21.push(trade);
      } else if (trade.week === 22) {
        week22.push(trade);
      } else if (trade.week === 23) {
        week23.push(trade);
      } else if (trade.week === 24) {
        week24.push(trade);
      } else if (trade.week === 25) {
        week25.push(trade);
      } else if (trade.week === 26) {
        week26.push(trade);
      } else if (trade.week === 27) {
        week27.push(trade);
      } else if (trade.week === 28) {
        week28.push(trade);
      } else if (trade.week === 29) {
        week29.push(trade);
      } else if (trade.week === 30) {
        week30.push(trade);
      } else if (trade.week === 31) {
        week31.push(trade);
      } else if (trade.week === 32) {
        week32.push(trade);
      } else if (trade.week === 33) {
        week33.push(trade);
      } else if (trade.week === 34) {
        week34.push(trade);
      } else if (trade.week === 35) {
        week35.push(trade);
      } else if (trade.week === 36) {
        week36.push(trade);
      } else if (trade.week === 37) {
        week37.push(trade);
      } else if (trade.week === 38) {
        week38.push(trade);
      } else if (trade.week === 39) {
        week39.push(trade);
      } else if (trade.week === 40) {
        week40.push(trade);
      } else if (trade.week === 41) {
        week41.push(trade);
      } else if (trade.week === 42) {
        week42.push(trade);
      } else if (trade.week === 43) {
        week43.push(trade);
      } else if (trade.week === 44) {
        week44.push(trade);
      } else if (trade.week === 45) {
        week45.push(trade);
      } else if (trade.week === 46) {
        week46.push(trade);
      } else if (trade.week === 47) {
        week47.push(trade);
      } else if (trade.week === 48) {
        week48.push(trade);
      } else if (trade.week === 49) {
        week49.push(trade);
      } else if (trade.week === 50) {
        week50.push(trade);
      } else if (trade.week === 51) {
        week51.push(trade);
      } else if (trade.week === 52) {
        week52.push(trade);
      }
    });

    // percentage
    let weeklyResult = [];
    let totalForEachWeek = [];
    // money
    let weeklyResultDollar = [];
    let totalForEachWeekDollar = [];

    for (let index = 0; index <= moment().week(); index++) {
      if (index === 1) {
        let percent = week1.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(percent);
        let money = week1.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(money);
      } else if (index === 2) {
        let percent = week2.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week2.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 3) {
        let percent = week3.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week3.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 4) {
        let percent = week4.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week4.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 5) {
        let percent = week5.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week5.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 6) {
        let percent = week6.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week6.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 7) {
        let percent = week7.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week7.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 8) {
        let percent = week8.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week8.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 9) {
        let percent = week9.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week9.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 10) {
        let percent = week10.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week10.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 11) {
        let percent = week11.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week11.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 12) {
        let percent = week12.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week12.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 13) {
        let percent = week13.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week13.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 14) {
        let percent = week14.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week14.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 15) {
        let percent = week15.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week15.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 16) {
        let percent = week16.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week16.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 17) {
        let percent = week17.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week17.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 18) {
        let percent = week18.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week18.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 19) {
        let percent = week19.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week19.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 20) {
        let percent = week20.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week20.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 21) {
        let percent = week21.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week21.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 22) {
        let percent = week22.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week22.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 23) {
        let percent = week23.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week23.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 24) {
        let percent = week24.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week24.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 25) {
        let percent = week25.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week25.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 26) {
        let percent = week26.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week26.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 27) {
        let percent = week27.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week27.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 28) {
        let percent = week28.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week28.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 29) {
        let percent = week29.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week29.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 30) {
        let percent = week30.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week30.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 31) {
        let percent = week31.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week31.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 32) {
        let percent = week32.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week32.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 33) {
        let percent = week33.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week33.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 34) {
        let percent = week34.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week34.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 35) {
        let percent = week35.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week35.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 36) {
        let percent = week36.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week36.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 37) {
        let percent = week37.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week37.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 38) {
        let percent = week38.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week38.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 39) {
        let percent = week39.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week39.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 40) {
        let percent = week40.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week40.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 41) {
        let percent = week41.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week41.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 42) {
        let percent = week42.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week42.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 43) {
        let percent = week43.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week43.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 44) {
        let percent = week44.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week44.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 45) {
        let percent = week45.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week45.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 46) {
        let percent = week46.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week46.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 47) {
        let percent = week47.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week47.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 48) {
        let percent = week48.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week48.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 49) {
        let percent = week49.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week49.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 50) {
        let percent = week50.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week50.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 51) {
        let percent = week51.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week51.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 52) {
        let percent = week52.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeek.push(percent);
        weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));
        let money = week52.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachWeekDollar.push(money);
        weeklyResultDollar.push(
          totalForEachWeekDollar.reduce((acc, curr) => acc + curr)
        );
      }
      setPercentReturn(weeklyResult);
      setMoneyReturn(weeklyResultDollar);
    }
  };
  const options = {
    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",

    interaction: {
      mode: "index",
      intersect: false,
    },

    stacked: false,
    plugins: {
      title: {
        display: false,
        text: "",
      },
    },

    scales: {
      x: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        display: true,
        position: "bottom",
        grid: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",

          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      y: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
      },
      y1: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        type: "linear",
        display: true,
        position: "left",

        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
      },
    },
  };
  const labels = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
  ];
  const data = {
    labels,
    datasets: [
      {
        label: personal
          ? "PC Percentage"
          : investor
          ? "IC Percentage"
          : all
          ? "PIC Percentage"
          : "",
        data: percentReturn,
        borderColor: "blue",
        backgroundColor: "blue",
        yAxisID: "y1",
      },
      {
        label: personal
          ? "PC Equity"
          : investor
          ? "IC Equity"
          : all
          ? "PIC Equity"
          : "",
        data: moneyReturn,
        borderColor: "red",
        backgroundColor: "red",
        yAxisID: "y",
      },
    ],
  };
  return <Line options={options} data={data} />;
};

export default Weekly;
