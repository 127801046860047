import { React, useContext } from "react";

import ThemeContext from "../../utils/theme-context";

import Chip from "@mui/material/Chip";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Stack from "@mui/material/Stack";

import Tooltip from "@mui/material/Tooltip";

const UnArchivedChipList = ({
  showSpecficTest,
  showBacktestTrades,
  handleArchivingTest,
  allbackTestSchemas,
  formSubmitted,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <Stack direction="row" spacing={1}>
      <Chip
        sx={{
          backgroundColor: theme[0] !== "dark" ? "#FCFCFF" : "",
          marginRight: "1em",
          color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
          border:
            showSpecficTest === "Missed Trades"
              ? " 2px solid #1976d2"
              : "2px solid rgba(128,128,128)",
        }}
        label={"Missed Trades"}
        onClick={() =>
          showBacktestTrades({
            startDate: JSON.parse(localStorage.getItem("user")).created,
            archived: "false",
            notes: "",
            pair: "All missed trades from",
            testId: "Missed Trades",
          })
        }
      />
      {allbackTestSchemas
        ?.filter((tests) => tests.archived?.includes("false"))
        .map((t) => {
          return (
            <Tooltip title={t.testId} key={t.testId}>
              <Chip
                deleteIcon={
                  !formSubmitted ? (
                    t.testId !== "Missed Trades" ? (
                      <Tooltip title="Archive Test">
                        <RemoveCircleOutlineIcon
                          sx={{
                            color: "#f23645 !important",
                          }}
                        />
                      </Tooltip>
                    ) : null
                  ) : null
                }
                sx={{
                  width: "130px",
                  backgroundColor: theme[0] !== "dark" ? "#FCFCFF" : "",
                  color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                  border:
                    showSpecficTest === t.testId
                      ? " 2px solid #1976d2"
                      : "2px solid rgba(128,128,128)",
                }}
                label={t.testId}
                onClick={() => showBacktestTrades(t)}
                onDelete={
                  !formSubmitted
                    ? t.testId !== "Missed Trades"
                      ? () => handleArchivingTest(t)
                      : null
                    : null
                }
              />
            </Tooltip>
          );
        })}
    </Stack>
  );
};

export default UnArchivedChipList;
