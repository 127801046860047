import { React, useContext, useState } from "react";

import dayjs from "dayjs";

import ThemeContext from "../../utils/theme-context";
import AuthContext from "../../utils/auth-context";
import { DataFetcher } from "../../utils/dataFetcher";

import CustomSelect from "../select/index";
import CustomSingleTextField from "../textfields/singleRow";
import CustomDateTimePicker from "../dateTimePickers/DateTimePicker";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import SaveIcon from "@mui/icons-material/Save";

import Tooltip from "@mui/material/Tooltip";

const Deposit = ({ open, onClose }) => {
  const auth = useContext(AuthContext);
  const throwMessage = auth?.throwMessage;
  const theme = useContext(ThemeContext);
  const [transactionType, setTransactionType] = useState("withdrawal");
  const [transanctionNotes, setTransanctionNotes] = useState("");
  const [transanctionAmount, setTransanctionAmount] = useState(0);
  const [transanctionReason, setTransanctionReason] = useState("");
  const [transanctionDate, setTransanctionDate] = useState(dayjs(new Date()));

  const [formSubmitted, setFormSubmitted] = useState(false);

  const submitTransaction = async (transaction, handleCloseNewDeposit) => {
    if (transanctionNotes && transanctionAmount && transanctionReason) {
      if (!formSubmitted);
      setFormSubmitted(true);
      const schema = {
        transanctionNotes: transanctionNotes,
        transanctionAmount: transanctionAmount,
        transanctionReason: transanctionReason,
        transactionType: transaction,
        date: transanctionDate,
      };
      const result = await DataFetcher("new-transaction", schema);

      if (result) {
        throwMessage("success", "Success");
        handleCloseNewDeposit();
        setTransanctionAmount(0);
        setTransanctionReason("");
        setTransanctionNotes("");
        setTransanctionDate(dayjs(new Date()));
        setFormSubmitted(false);
      } else {
        throwMessage("error", "Something went wrong");
        setFormSubmitted(false);
      }
    } else {
      throwMessage("warning", "Missing Fields");
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "rgba(40, 42, 46)",
          width: "60%",
          maxWidth: "700px",
          maxHeight: "700px",
          backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          borderRadius: "10px",
          border:
            theme[0] !== "dark"
              ? "1px solid rgba(37,37,37)"
              : " 1px solid #FCFCFF",
          p: 4,
          outline: "none",
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
          >
            <h3
              style={{
                textAlign: "center",
                fontWeight: "200",
                borderBottom:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid rgba(37,37,37)",
                width: "400px",
                margin: "0 auto 10px auto",
                padding: "0 0 10px 0",
              }}
            >
              {transactionType === "withdrawal"
                ? "Withdrawal"
                : transactionType === "deposit"
                ? "Deposit"
                : null}
            </h3>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
          >
            <FormControl
              sx={{ display: "flex", alignItems: "center" }}
              onChange={(e) => setTransactionType(e.target.value)}
            >
              <RadioGroup row>
                <FormControlLabel
                  checked={transactionType === "withdrawal"}
                  value="withdrawal"
                  control={
                    <Radio
                      sx={{
                        color:
                          theme[0] === "dark"
                            ? "#FCFCFF !important"
                            : "rgba(37,37,37) !important",
                        "&.Mui-checked": {
                          color:
                            theme[0] === "dark"
                              ? "#FCFCFF !important"
                              : "rgba(37,37,37) !important",
                        },
                      }}
                    />
                  }
                  label="Withdrawal"
                />
                <FormControlLabel
                  value="deposit"
                  control={
                    <Radio
                      sx={{
                        color:
                          theme[0] === "dark"
                            ? "#FCFCFF !important"
                            : "rgba(37,37,37) !important",
                        "&.Mui-checked": {
                          color:
                            theme[0] === "dark"
                              ? "#FCFCFF !important"
                              : "rgba(37,37,37) !important",
                        },
                      }}
                    />
                  }
                  label="Deposit"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <CustomDateTimePicker
              size="small"
              label={
                transactionType === "withdrawal"
                  ? "Withdrawal Date"
                  : transactionType === "deposit"
                  ? "Deposit Date"
                  : ""
              }
              value={transanctionDate}
              onChange={(newValue) => setTransanctionDate(newValue.$d)}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomSingleTextField
              size="small"
              type="number"
              value={transanctionAmount}
              label={
                transactionType === "withdrawal"
                  ? "Withdrawal Amount"
                  : transactionType === "deposit"
                  ? "Deposit Amount"
                  : ""
              }
              onChange={(e) => setTransanctionAmount(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomSelect
              size="small"
              inputLabel={
                transactionType === "withdrawal"
                  ? "Withdrawal Type"
                  : transactionType === "deposit"
                  ? "Deposit Type"
                  : ""
              }
              value={transanctionReason}
              label={
                transactionType === "withdrawal"
                  ? "Withdrawal Type"
                  : transactionType === "deposit"
                  ? "Deposit Type"
                  : ""
              }
              onChange={(e) => setTransanctionReason(e.target.value)}
              menuItemArray={JSON.stringify([
                { value: "Personal", label: "Personal Capital" },
                // { value: "Investment", label: "Investment Capital" },
                { value: "Financing", label: "Broker Financing" },
              ])}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomSingleTextField
              size="small"
              value={transanctionNotes}
              label={
                transactionType === "withdrawal"
                  ? "Withdrawal Note"
                  : transactionType === "deposit"
                  ? "Deposit Note"
                  : ""
              }
              onChange={(e) => setTransanctionNotes(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            height: "47px",
            margin: "1.5em 0 -15px 0",
          }}
        >
          <Tooltip title="Save">
              <SaveIcon
                sx={{
                  color: formSubmitted ? "lightGrey" : "#26a65d",
                  cursor: "pointer",
                }}
                fontSize="large"
                onClick={() => submitTransaction(transactionType, onClose)}
              />
            </Tooltip>
        </Grid>
      </Box>
    </Modal>
  );
};

export default Deposit;
