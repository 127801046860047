import React, { useContext } from "react";
import moment from "moment";
import Logic from "./logic.js";
import ThemeContext from "../utils/theme-context.js";

const Clock = () => {
  const theme = useContext(ThemeContext);

  const {
    sydneyOpen,
    newYorkOpen,
    tokyoOpen,
    londonOpen,
    sydneyClose,
    newYorkClose,
    TokyoClose,
    londonClose,
    market,
    currentTime,
    sydney,
    newYork,
    tokyo,
    london,
    sydneyOpenTimer,
    newYorkOpenTimer,
    tokyoOpenTimer,
    londonOpenTimer,
  } = Logic();

  const ulStyles = {
    display: "flex",
    width: "100%",
    listStyleType: "none",
    color: theme[0] === "dark" ? "#FCFCFF" : "black",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: "0",
    margin: "0 auto",
  };
  const liStyles = {
    fontSize: "0.7em",
    width: "230px",
    textAlign: "center",
    margin: "0 auto",
  };
  const spanStyles = {
    fontSize: "0.95em",
    letterSpacing: "1px",
  };

  const sydney_open = moment.duration(
    moment(sydneyOpen).startOf("hour").add(1, "minutes").diff(currentTime)
  );
  const sydney_close = moment.duration(
    moment(sydneyClose).startOf("hour").add(1, "minutes").diff(currentTime)
  );

  const tokyo_open = moment.duration(
    moment(tokyoOpen).startOf("hour").add(1, "minutes").diff(currentTime)
  );
  const tokyo_close = moment.duration(
    moment(TokyoClose).startOf("hour").add(1, "minutes").diff(currentTime)
  );
  const london_open = moment.duration(
    moment(londonOpen).startOf("hour").add(1, "minutes").diff(currentTime)
  );
  const london_close = moment.duration(
    moment(londonClose).startOf("hour").add(1, "minutes").diff(currentTime)
  );
  const new_york_open = moment.duration(
    moment(newYorkOpen).startOf("hour").add(1, "minutes").diff(currentTime)
  );
  const new_york_close = moment.duration(
    moment(newYorkClose).startOf("hour").add(1, "minutes").diff(currentTime)
  );

  const addLeadingZero = (time) => {
    return time;
    // const string = time.toString();
    // if (string.length === 1) {
    //   return `0${string}`;
    // } else {
    //   return time;
    // }
  };

  return market ? (
    <div>
      <ul style={ulStyles}>
        {sydneyOpenTimer && (
          <li style={liStyles}>
            Sydney Open{" "}
            <span style={spanStyles}>
              {sydney_open.hours() > 0
                ? addLeadingZero(sydney_open.hours())
                : null}
              {sydney_open.hours() > 1
                ? "hrs"
                : sydney_open.hours() === 1
                ? "hr"
                : null}{" "}
              {sydney_open.minutes() > 0
                ? addLeadingZero(sydney_open.minutes())
                : null}
              {sydney_open.minutes() > 1
                ? "mins"
                : sydney_open.minutes() === 1
                ? "min"
                : null}
            </span>
          </li>
        )}

        {sydney && (
          <li style={liStyles}>
            Sydney Close{" "}
            <span style={spanStyles}>
              {sydney_close.hours() > 0
                ? addLeadingZero(sydney_close.hours())
                : null}
              {/* {":"} */}
              {sydney_close.hours() > 1
                ? "hrs"
                : sydney_close.hours() === 1
                ? "hr"
                : null}{" "}
              {sydney_close.minutes() > 0
                ? addLeadingZero(sydney_close.minutes())
                : null}
              {sydney_close.minutes() > 1
                ? "mins"
                : sydney_close.minutes() === 1
                ? "min"
                : null}
            </span>
          </li>
        )}

        {tokyoOpenTimer && (
          <li style={liStyles}>
            Tokyo Open{" "}
            <span style={spanStyles}>
              {tokyo_open.hours() > 0
                ? addLeadingZero(tokyo_open.hours())
                : null}
              {tokyo_open.hours() > 1
                ? "hrs"
                : tokyo_open.hours() === 1
                ? "hr"
                : null}{" "}
              {tokyo_open.minutes() > 0
                ? addLeadingZero(tokyo_open.minutes())
                : null}
              {tokyo_open.minutes() > 1
                ? "mins"
                : tokyo_open.minutes() === 1
                ? "min"
                : null}
            </span>
          </li>
        )}

        {tokyo && (
          <li style={liStyles}>
            Tokyo Close{" "}
            <span style={spanStyles}>
              {tokyo_close.hours() > 0
                ? addLeadingZero(tokyo_close.hours())
                : null}
              {tokyo_close.hours() > 1
                ? "hrs"
                : tokyo_close.hours() === 1
                ? "hr"
                : null}{" "}
              {tokyo_close.minutes() > 0
                ? addLeadingZero(tokyo_close.minutes())
                : null}
              {tokyo_close.minutes() > 1
                ? "mins"
                : tokyo_close.minutes() === 1
                ? "min"
                : null}
            </span>
          </li>
        )}

        {londonOpenTimer && (
          <li style={liStyles}>
            London Open{" "}
            <span style={spanStyles}>
              {london_open.hours() > 0
                ? addLeadingZero(london_open.hours())
                : null}
              {london_open.hours() > 1
                ? "hrs"
                : london_open.hours() === 1
                ? "hr"
                : null}{" "}
              {london_open.minutes() > 0
                ? addLeadingZero(london_open.minutes())
                : null}
              {london_open.minutes() > 1
                ? "mins"
                : london_open.minutes() === 1
                ? "min"
                : null}
            </span>
          </li>
        )}

        {london && (
          <li style={liStyles}>
            London Close{" "}
            <span style={spanStyles}>
              {london_close.hours() > 0
                ? addLeadingZero(london_close.hours())
                : null}
              {london_close.hours() > 1
                ? "hrs"
                : london_close.hours() === 1
                ? "hr"
                : null}{" "}
              {london_close.minutes() > 0
                ? addLeadingZero(london_close.minutes())
                : null}
              {london_close.minutes() > 1
                ? "mins"
                : london_close.minutes() === 1
                ? "min"
                : null}
            </span>
          </li>
        )}

        {newYorkOpenTimer && (
          <li style={liStyles}>
            New York Open{" "}
            <span style={spanStyles}>
              {new_york_open.hours() > 0
                ? addLeadingZero(new_york_open.hours())
                : null}
              {new_york_open.hours() > 1
                ? "hrs"
                : new_york_open.hours() === 1
                ? "hr"
                : null}{" "}
              {new_york_open.minutes() > 0
                ? addLeadingZero(new_york_open.minutes())
                : null}
              {new_york_open.minutes() > 1
                ? "mins"
                : new_york_open.minutes() === 1
                ? "min"
                : null}
            </span>
          </li>
        )}

        {newYork && (
          <li style={liStyles}>
            New York Close{" "}
            <span style={spanStyles}>
              {new_york_close.hours() > 0
                ? addLeadingZero(new_york_close.hours())
                : null}
              {new_york_close.hours() > 1
                ? "hrs"
                : new_york_close.hours() === 1
                ? "hr"
                : null}{" "}
              {new_york_close.minutes() > 0
                ? addLeadingZero(new_york_close.minutes())
                : null}
              {new_york_close.minutes() > 1
                ? "mins"
                : new_york_close.minutes() === 1
                ? "min"
                : null}{" "}
            </span>
          </li>
        )}
      </ul>
    </div>
  ) : (
    <ul style={ulStyles}>
      <li style={liStyles}>Market Closed</li>
    </ul>
  );
};

export default Clock;

