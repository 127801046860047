// import styled from "styled-components";
import { Container } from "@mui/material";

import { styled as Style } from "@mui/material/styles";

export const Wrapper = Style(Container)({
  margin: "auto",
  padding: "0 !important",
  // maxWidth: '1920px',
  height: "100%",
  color: "#FCFCFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});


