import { React, useContext } from "react";

import ThemeContext from "../../utils/theme-context";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";


const BacktestingModeSwitch = ({ label, checked, onChange,name }) => {
    const theme = useContext(ThemeContext);
    return (
        <FormControlLabel
            sx={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
            }}
            control={<Switch  />}
            label={label}
            checked={checked}
            onChange={onChange}
            name={name}
           
        />
    )
}

export default BacktestingModeSwitch