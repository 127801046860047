import { useContext, useEffect, useState } from "react";
import ThemeContext from "../../utils/theme-context";
import AuthContext from "../../utils/auth-context";
import Monthly from "./monthly";
import Weekly from "./weekly";
import Quarterly from "./quarterly";
import CustomButtom from "../buttons/";
import moment from "moment";
import { animateScroll as scroll } from "react-scroll";

const EquityCurve = ({ calculateFields, trades }) => {
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  const [years, setYears] = useState([]);
  const year = moment().format("Y");
  const [selected, setSelected] = useState(0);
  const [selectedTimeframe, setSelectedTimeframe] = useState(0);
  const timeframes = ["Weekly", "Monthly", "Quarterly"];

  useEffect(() => {
    scroll.scrollToBottom();
    let all_trades = trades;
    if (auth.backtestingMode) {
      all_trades = trades.filter((tests) => tests?.id?.includes("backtest-"));
    } else {
      all_trades = trades.filter((tests) => tests?.id?.includes("live-"));
    }
    all_trades = all_trades.map((trade) => {
      return moment(trade.entryDate, "DD/MM/YYYY").format("Y");
    });
    all_trades = [...new Set(all_trades)];
    setYears(all_trades);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeYear = () => {
    setSelected((prev) => {
      if (prev === years.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
    scroll.scrollToBottom();
  };
  const handleChangeTimeframe = () => {
    setSelectedTimeframe((prev) => {
      if (prev === timeframes.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
    scroll.scrollToBottom();
  };

  return (
    <div
      style={{
        minHeight: "100%",
        width: "100%",
        backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
      }}
    >
      <div
        style={{
          // height: "85vh",
          width: "100%s",
          margin: "1em auto 0 auto",
          padding: "0.7em 1em 1em 1em",
        }}
      >
        <div
          style={{
            // border: '1px solid red',
            height: "45px",
            width: "200px",
            margin: "-1.2em 0 0em 0",
          }}
        >
          <CustomButtom
            variant="outlined"
            label={years[selected]}
            onClick={() => handleChangeYear()}
          />
          <CustomButtom
            variant="outlined"
            label={timeframes[selectedTimeframe]}
            onClick={() => handleChangeTimeframe()}
          />
        </div>
        <br />
        {selectedTimeframe === 0 ? (
          <Weekly
            calculateFields={calculateFields}
            theme={theme}
            years={years[selected]}
            selected={selected}
            year={year}
          />
        ) : selectedTimeframe === 1 ? (
          <Monthly
            calculateFields={calculateFields}
            theme={theme}
            years={years[selected]}
            selected={selected}
            year={year}
          />
        ) : selectedTimeframe === 2 ? (
          <Quarterly
            calculateFields={calculateFields}
            theme={theme}
            years={years[selected]}
            selected={selected}
            year={year}
          />
        ) : null}
      </div>
    </div>
  );
};

export default EquityCurve;
