import { useContext, useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import moment from "moment";
import dayjs from "dayjs";
import AuthContext from "../utils/auth-context";
import ThemeContext from "../utils/theme-context";
import { DataFetcher } from "../utils/dataFetcher";

import throwMessage from "../utils/throwMessage";
import markets from "../lib/markets";

import CustomSelect from "../components/select/index";
import CustomMultiTextField from "../components/textfields/multiRow_standard";
import CustomDatePicker from "../components/dateTimePickers/datePicker";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import Tooltip from "@mui/material/Tooltip";

import { useLongPress } from "use-long-press";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import ImageViewer from "react-simple-image-viewer";

import useWindowDimensions from "../utils/width";


const imgStyle = {
  width: "100%",
  height: "430px",
  objectFit: "cover",
  borderRadius: "15px",
  cursor: "pointer",
  padding: "10px",
};

const SingleForecasts = ({
  setSingleForecastView,
  handleScroll,
  setSingleReflectionView,
}) => {

  const { width } = useWindowDimensions();
  const auth = useContext(AuthContext);
  const theme = useContext(ThemeContext);

  const [tradeId, setTradeId] = useState("");
  const [forecast_id, setForecast_id] = useState("");
  const [pair, setPair] = useState("");
  const [direction, setDirection] = useState("");
  const [date, setDate] = useState(dayjs(new Date()));
  const [sequence, setSequence] = useState(undefined);
  const [positioning, setPositioning] = useState(undefined);

  const [grade, setGrade] = useState(undefined);
  const [dailyLink, setDailyLink] = useState("");
  const [hourlyLink, setHourlyLink] = useState("");
  const [dailyDescrtpiton, setDailyDescrtpiton] = useState("");
  const [hourlyDescrtpiton, setHourlyDescrtpiton] = useState("");

  const [dailyDescrtpitonAfter, setDailyDescrtpitonAfter] = useState("");
  const [hourlyDescrtpitonAfter, setHourlyDescrtpitonAfter] = useState("");

  const [lowerTimeframeName, setLowerTimeframeName] = useState("");
  const [higherTimeframeName, setHigherTimeframeName] = useState("");
  const [dailyLinkAfter, setDailyLinkAfter] = useState("");
  const [hourlyLinkAfter, setHourlyLinkAfter] = useState("");

  const [changeDate, setChangeDate] = useState(false);
  const [lowerNameEdit, setLowerNameEdit] = useState(false);
  const [higherNameEdit, setHigherNameEdit] = useState(false);
  const [active, setActive] = useState("");

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [forecastingBacktesing, setForecastingBacktesing] = useState(undefined);

  const [isHoveringNext, setIsHoveringNext] = useState(false);
  const [isHoveringBack, setIsHoveringBack] = useState(false);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  useEffect(() => {
    let singleForecast;
    singleForecast = auth.forecasts.find((forecast) =>
      forecast?._id.includes(localStorage.getItem("forecast-to-view"))
    );
    setForecastingBacktesing(false);
    if (!singleForecast) {
      singleForecast = auth.forecastsBacktesting.find((forecast) =>
        forecast?._id.includes(localStorage.getItem("forecast-to-view"))
      );
      setForecastingBacktesing(true);
    }

    const trade_id = auth.trades.find(
      (t) => t.foreCastId === singleForecast?._id
    );
    setForecast_id(localStorage.getItem("forecast-to-view"));
    setTradeId(trade_id?._id);
    setActive(singleForecast?.active);
    setDate(singleForecast?.date);
    setPair(singleForecast?.pair);
    setDirection(singleForecast?.direction);
    setSequence(singleForecast?.sequence);
    setPositioning(singleForecast?.positioning);
    setGrade(singleForecast?.grade);
    setDailyLink(singleForecast?.dailyLink);
    setHourlyLink(singleForecast?.hourlyLink);
    setDailyLinkAfter(singleForecast?.dailyLinkAfter);
    setHourlyLinkAfter(singleForecast?.hourlyLinkAfter);
    setDailyDescrtpiton(singleForecast?.dailyDescrtpiton);
    setHourlyDescrtpiton(singleForecast?.hourlyDescrtpiton);
    setLowerTimeframeName(singleForecast?.lowerTimeframeName);
    setHigherTimeframeName(singleForecast?.higherTimeframeName);
    setHourlyDescrtpitonAfter(singleForecast.hourlyDescrtpitonAfter);
    setDailyDescrtpitonAfter(singleForecast.dailyDescrtpitonAfter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeForecast = (direction, pass) => {
    let next;
    let back;

    let array = pass;

    if (pass.length > 1) {
      array = array.filter((forecast) => {
        return moment(date).isSame(forecast.date, "week");
      });

      array = array.sort((a, b) => a.pair.localeCompare(b.pair));

      array = array.sort((a, b) => {
        return moment(a.date).diff(b.date);
      });

      const foundIndex = array.findIndex((x) => x._id === forecast_id);

      next = foundIndex + 1;
      back = foundIndex - 1;

      if (foundIndex === 0) {
        back = array.length - 1;
      }
      if (foundIndex + 1 === array.length) {
        next = 0;
      }

      if (direction === "Next") {
        const trade_id = auth.trades.find(
          (t) => t.foreCastId === array[next]?._id
        );
        setTradeId(trade_id?._id);
        setForecast_id(array[next]?._id);
        setActive(array[next].active);
        setDate(array[next].date);
        setPair(array[next].pair);
        setDirection(array[next].direction);
        setSequence(array[next].sequence);
        setPositioning(array[next].positioning);
        setGrade(array[next].grade);
        setDailyLink(array[next].dailyLink);
        setHourlyLink(array[next].hourlyLink);
        setDailyLinkAfter(array[next].dailyLinkAfter);
        setHourlyLinkAfter(array[next].hourlyLinkAfter);
        setDailyDescrtpiton(array[next].dailyDescrtpiton);

        setDailyLinkAfter(array[next].dailyLinkAfter);
        setHourlyLinkAfter(array[next].hourlyLinkAfter);

        setHourlyDescrtpiton(array[next].hourlyDescrtpiton);
        setLowerTimeframeName(array[next].lowerTimeframeName);
        setHigherTimeframeName(array[next].higherTimeframeName);
      } else if (direction === "Back") {
        const trade_id = auth.trades.find(
          (t) => t.foreCastId === array[back]?._id
        );
        setTradeId(trade_id?._id);
        setForecast_id(array[back]?._id);
        setActive(array[back].active);
        setDate(array[back].date);
        setPair(array[back].pair);
        setDirection(array[back].direction);
        setSequence(array[back].sequence);
        setPositioning(array[back].positioning);
        setGrade(array[back].grade);
        setDailyLink(array[back].dailyLink);
        setHourlyLink(array[back].hourlyLink);
        setDailyLinkAfter(array[back].dailyLinkAfter);

        setDailyLinkAfter(array[back].dailyLinkAfter);
        setHourlyLinkAfter(array[back].hourlyLinkAfter);

        setHourlyLinkAfter(array[back].hourlyLinkAfter);
        setDailyDescrtpiton(array[back].dailyDescrtpiton);
        setHourlyDescrtpiton(array[back].hourlyDescrtpiton);
        setLowerTimeframeName(array[back].lowerTimeframeName);
        setHigherTimeframeName(array[back].higherTimeframeName);
      }
    }
  };
  const goBack = () => {
    setSingleForecastView(false);
    // handleScroll();
    localStorage.removeItem("forecast-to-view");
    if (localStorage.getItem("reflection-to-view")) {
      setSingleReflectionView(true);
    }
    if (localStorage.getItem("from-tracker") === "true") {
      auth.setTab(1);
      localStorage.removeItem("from-tracker");
    }
  };
  const handleChangeSequence = (event) => {
    setSequence(event.target.value);
  };
  const handleChangePositioning = (event) => {
    setPositioning(event.target.value);
  };
  const handleChangeGrade = (event) => {
    setGrade(event.target.value);
  };
  const deleteForecast = async (goBack) => {
    const schema = {
      _id: localStorage.getItem("forecast-to-view"),
      backtestingMode: auth.backtestingMode,
    };

    const result = await DataFetcher("delete-forecast", schema);
    if (result) {
      if (auth.backtestingMode) {
        const filteredArray = auth.forecastsBacktesting.filter(
          (x) => x?._id !== localStorage.getItem("forecast-to-view")
        );

        auth.setForecastsBacktesting(filteredArray);
      } else {
        const filteredArray = auth.forecasts.filter(
          (x) => x?._id !== localStorage.getItem("forecast-to-view")
        );

        auth.setForecasts(filteredArray);
      }
      throwMessage("success", "Deleted");
      goBack();
    }
  };
  const updateFocusMode = async (focus) => {
    if (!formSubmitted);
    setFormSubmitted(true);
    const result = await DataFetcher("edit-forecast", {
      active: focus,
      _id: forecast_id,
      backtestingMode: auth.backtestingMode,
    });

    if (result) {
      if (auth.backtestingMode) {
        const filteredArray = auth.forecastsBacktesting.filter(
          (x) => x?._id !== forecast_id
        );
        filteredArray.push(result);
        auth.setForecastsBacktesting(filteredArray);
      } else {
        const filteredArray = auth.forecasts.filter(
          (x) => x?._id !== forecast_id
        );
        filteredArray.push(result);
        auth.setForecasts(filteredArray);
      }
      setTimeout(() => {
        setFormSubmitted(false);
      }, 3000);
    } else {
      throwMessage("error", "Something went wrong");
      setFormSubmitted(false);
    }
  };
  const handleSave = async () => {
    const schema = {
      _id: forecast_id,
      pair: pair,
      direction: direction,
      date: date,
      sequence: sequence,
      positioning: positioning,
      grade: grade,
      lowerTimeframeName: lowerTimeframeName,
      higherTimeframeName: higherTimeframeName,
      dailyLink: dailyLink,
      hourlyLink: hourlyLink,
      hourlyLinkAfter: hourlyLinkAfter,
      dailyLinkAfter: dailyLinkAfter,
      dailyDescrtpiton: dailyDescrtpiton,
      hourlyDescrtpiton: hourlyDescrtpiton,
      dailyDescrtpitonAfter: dailyDescrtpitonAfter,
      hourlyDescrtpitonAfter: hourlyDescrtpitonAfter,
      backtestingMode: auth.backtestingMode,
      active: active,
    };
    if (!formSubmitted);
    setFormSubmitted(true);
    const result = await DataFetcher("edit-forecast", schema);

    if (result) {
      if (auth.backtestingMode) {
        const filteredArray = auth.forecastsBacktesting.filter(
          (x) => x?._id !== forecast_id
        );
        filteredArray.push(result);
        auth.setForecastsBacktesting(filteredArray);
      } else {
        const filteredArray = auth.forecasts.filter(
          (x) => x?._id !== forecast_id
        );
        filteredArray.push(result);
        auth.setForecasts(filteredArray);
      }
      throwMessage("success", "Saved");
      setFormSubmitted(false);
    } else {
      throwMessage("error", "Something went wrong");
      setFormSubmitted(false);
    }
  };
  const callback = useCallback((props) => {
    switch (props.target.getAttribute("id")) {
      case "setPair":
        setPair("");
        break;
      case "setDirection":
        setDirection("");
        break;

      case "setChangeDate":
        setChangeDate(true);
        break;

      case "setSequence":
        setSequence(undefined);
        break;

      case "setPositioning":
        setPositioning(undefined);
        break;

      case "setGrade":
        setGrade(undefined);
        break;

      case "setHigherNameEditsetHigherTimeframeName":
        setHigherNameEdit(true);
        setHigherTimeframeName("");
        break;

      case "setLowerNameEditsetLowerTimeframeName":
        setLowerNameEdit(true);
        setLowerTimeframeName("");
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const callbackDeleteLinks = useCallback((props) => {
    switch (props.target.getAttribute("id")) {
      case "setDailyLink":
        setDailyLink("");
        break;

      case "setHourlyLink":
        setHourlyLink("");
        break;

      case "setDailyLinkAfter":
        setDailyLinkAfter("");
        break;

      case "setHourlyLinkAfter":
        setHourlyLinkAfter("");
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const callbackDelete = useCallback((props) => {
    deleteForecast(goBack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bind = useLongPress(callback, { threshold: 1000 });
  const bindDelete = useLongPress(callbackDelete, { threshold: 3000 });
  const bindDeleteLinks = useLongPress(callbackDeleteLinks, {
    threshold: 2500,
  });
  const urls = [dailyLink, hourlyLink, dailyLinkAfter, hourlyLinkAfter];

  const getTitle = (index) => {
    switch (index) {
      case 0:
        return higherTimeframeName + " Before";
      case 1:
        return lowerTimeframeName + " Before";
      case 2:
        return higherTimeframeName + " After";
      case 3:
        return lowerTimeframeName + " After";
      default:
        break;
    }
  };

  const getUrl = (index) => {
    switch (index) {
      case 0:
        return urls[0];
      case 1:
        return urls[1];
      case 2:
        return urls[2];
      case 3:
        return urls[3];
      default:
        break;
    }
  };

  useEffect(() => {
    document.onkeydown = checkKey;
    function checkKey(e) {
      if (e.keyCode === 39) {
        closeImageViewer(0);
      }
      if (e.keyCode === 37) {
        closeImageViewer(0);
      }
      if (e.keyCode === 76) {
        closeImageViewer(0);
      }
      if (e.keyCode === 72) {
        closeImageViewer(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        overflowY: !isViewerOpen ? "scroll" : null,
        overflowX: !isViewerOpen ? "hidden" : null,
        position: !isViewerOpen ? "absolute" : "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        width: "100%",
        maxWidth: "1920px",
        height: "100%",
        backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        padding: "10px 15px 15px 15px",
        margin: "0 auto",
      }}
    >
      {isViewerOpen && (
        <div style={{ height: "100%", width: "100%" }}>
          <ImageViewer
            src={urls}
            currentIndex={currentImage}
            disableScroll={true}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
            leftArrowComponent={<p style={{ display: "none" }}></p>}
            rightArrowComponent={<p style={{ display: "none" }}></p>}
            closeComponent={<p style={{ display: "none" }}></p>}
          />

          <a
            target="_blank"
            href={getUrl(currentImage)}
            rel="noreferrer"
            style={{
              textDecoration: "none",
              textAlign: "center",
              fontSize: "14px",
              color: "white",
              position: "absolute",
              transform: "translate(-50%, -50%)",
              left: "50%",
              top: "17px",
              zIndex: "1",
              backgroundColor: "black",
              padding: "0.6em 1em",
              borderRadius: "0 0 5px 5px",
              border: "1px solid white",
            }}
          >
            {getTitle(currentImage)}
          </a>
        </div>
      )}

      {!localStorage.getItem("reflection-to-view") && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "0.7em",
            margin: width <= 500 ? "5em 1em 0 1em" : "0em 1em 0 1em",
            color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          }}
        >
          <p
            onClick={() =>
              changeForecast(
                "Back",
                forecastingBacktesing
                  ? auth.forecastsBacktesting
                  : auth.forecasts
              )
            }
            onMouseEnter={() => setIsHoveringBack(true)}
            onMouseLeave={() => setIsHoveringBack(false)}
            style={{
              cursor: "pointer",
              position: "fixed",
              left: "60px",
              top: "35px",
              color: isHoveringBack ? "#ED9E0E" : "",
            }}
          >
            Back
          </p>
          <p
            onMouseEnter={() => setIsHoveringNext(true)}
            onMouseLeave={() => setIsHoveringNext(false)}
            style={{
              cursor: "pointer",
              position: "fixed",
              right: "60px",
              top: "35px",
              color: isHoveringNext ? "#ED9E0E" : "",
            }}
            onClick={() =>
              changeForecast(
                "Next",
                forecastingBacktesing
                  ? auth.forecastsBacktesting
                  : auth.forecasts
              )
            }
          >
            Next
          </p>
        </div>
      )}

      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        sx={{ minHeight: "100%" }}
      >
        <Grid
          item
          xs={12}
          sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "100",
              borderBottom:
                theme[0] === "dark"
                  ? " 1px solid #FCFCFF"
                  : "1px solid rgba(37,37,37)",
              maxWidth: "750px",
              margin: "1em auto 50px auto",
              padding: "0 0 15px 0",
              cursor: "pointer",
              fontSize: "26px",
            }}
          >
            <span id="setPair" {...bind()}>
              {pair}
            </span>{" "}
            <span
              style={{
                color:
                  direction === "Short"
                    ? "#db0671"
                    : direction === "Long"
                    ? "#26a65d"
                    : "#ED9E0E",
              }}
              id="setDirection"
              {...bind()}
            >
              {direction}
            </span>{" "}
            <span id="setChangeDate" {...bind()}>
              {date && moment(date).format("dddd Do YYYY")}
            </span>{" "}
            Forecast{" "}
            <Tooltip
              title={
                active === "true"
                  ? "Toggle focus mode off"
                  : "Toggle focus mode on"
              }
            >
              <span>
                {active === "true" ? (
                  <AlarmOnIcon
                    onClick={() => {
                      setActive("false");
                      updateFocusMode(false);
                    }}
                    style={{ color: "#26a65d" }}
                  />
                ) : (
                  <AlarmOffIcon
                    onClick={() => {
                      setActive("true");
                      updateFocusMode(true);
                    }}
                    style={{ color: "#f23645" }}
                  />
                )}
              </span>
            </Tooltip>
          </h3>
        </Grid>

        {!pair ? (
          <Grid item xs={12} sx={{ margin: "2em auto" }}>
            <div style={{ width: "300px", margin: "0 auto" }}>
              <CustomSelect
                size="small"
                inputLabel="Instrument"
                value={pair}
                label="Instrument"
                onChange={(event) => setPair(event.target.value)}
                menuItemArray={JSON.stringify(
                  markets.map((item) => {
                    return { value: item.ticker, label: item.label };
                  })
                )}
              />
            </div>
          </Grid>
        ) : null}
        {!direction ? (
          <Grid item xs={12} sx={{ margin: "2em auto" }}>
            <div style={{ width: "300px", margin: "0 auto" }}>
              <CustomSelect
                size="small"
                inputLabel="Direction"
                value={direction}
                label="Direction"
                onChange={(e) => setDirection(e.target.value)}
                menuItemArray={JSON.stringify([
                  { value: "Short", label: "Short" },
                  { value: "Long", label: "Long" },
                  { value: "Netural", label: "Netural" },
                ])}
              />
            </div>
          </Grid>
        ) : null}

        {changeDate ? (
          <Grid item xs={12} sx={{ margin: "0em auto 2em auto" }}>
            <div style={{ width: "300px", margin: "0 auto" }}>
              <CustomDatePicker
                size="small"
                label="Event Date"
                value={date}
                onChange={(newValue) => {
                  setChangeDate(false);
                  setDate(newValue.$d);
                }}
              />
            </div>
          </Grid>
        ) : null}
        <div
          style={{
            margin: "0 auto",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
          }}
        >
          {!positioning ? (
            <div
              style={{
                margin: "0 auto",
              }}
            >
              <FormControl>
                <FormLabel
                  sx={{
                    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    textAlign: "center",
                    fontSize: "15px",
                  }}
                  id="positioning"
                >
                  Positioning
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="positioning"
                  defaultValue={positioning}
                  sx={{
                    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  }}
                >
                  <FormControlLabel
                    value="Poor Positioning"
                    control={
                      <Radio
                        onChange={handleChangePositioning}
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      />
                    }
                    label={
                      <p
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        Poor
                      </p>
                    }
                  />
                  <FormControlLabel
                    value="Average Positioning"
                    control={
                      <Radio
                        onChange={handleChangePositioning}
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      />
                    }
                    label={
                      <p
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        Average
                      </p>
                    }
                  />
                  <FormControlLabel
                    value="Excellent Positioning"
                    control={
                      <Radio
                        onChange={handleChangePositioning}
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      />
                    }
                    label={
                      <p
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        Excellent
                      </p>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              <p
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  color:
                    positioning === "Excellent Positioning"
                      ? "#26a65d"
                      : positioning === "Average Positioning"
                      ? "#ED9E0E"
                      : "#f23645",
                }}
                id="setPositioning"
                {...bind()}
              >
                {positioning}
              </p>
            </div>
          )}

          {!sequence ? (
            <div
              style={{
                margin: "0 auto",
              }}
            >
              <FormControl>
                <FormLabel
                  sx={{
                    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    textAlign: "center",
                    fontSize: "15px",
                  }}
                  id="sequence"
                >
                  Sequence
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="sequence"
                  defaultValue={sequence}
                  sx={{
                    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  }}
                >
                  <FormControlLabel
                    value="Poor Sequence"
                    control={
                      <Radio
                        onChange={handleChangeSequence}
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      />
                    }
                    label={
                      <p
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        Poor
                      </p>
                    }
                  />
                  <FormControlLabel
                    value="Average Sequence"
                    control={
                      <Radio
                        onChange={handleChangeSequence}
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      />
                    }
                    label={
                      <p
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        Average
                      </p>
                    }
                  />
                  <FormControlLabel
                    value="Excellent Sequence"
                    control={
                      <Radio
                        onChange={handleChangeSequence}
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      />
                    }
                    label={
                      <p
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        Excellent
                      </p>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              <p
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  color:
                    sequence === "Excellent Sequence"
                      ? "#26a65d"
                      : sequence === "Average Sequence"
                      ? "#ED9E0E"
                      : "#f23645",
                }}
                id="setSequence"
                {...bind()}
              >
                {sequence}
              </p>
            </div>
          )}

          {!grade ? (
            <div
              style={{
                margin: "0 auto",
              }}
            >
              <FormControl>
                <FormLabel
                  sx={{
                    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    textAlign: "center",
                    fontSize: "15px",
                  }}
                  id="grade"
                >
                  Grade
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="grade"
                  defaultValue={grade}
                  sx={{
                    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  }}
                >
                  <FormControlLabel
                    value="Low Risk"
                    control={
                      <Radio
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                        onChange={handleChangeGrade}
                      />
                    }
                    label={
                      <p
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        Low Risk
                      </p>
                    }
                  />
                  <FormControlLabel
                    value="High Risk"
                    control={
                      <Radio
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                        onChange={handleChangeGrade}
                      />
                    }
                    label={
                      <p
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        High Risk
                      </p>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              <p
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  color: grade === "Low Risk" ? "#26a65d" : "#f23645",
                }}
                id="setGrade"
                {...bind()}
              >
                {grade}
              </p>
            </div>
          )}
        </div>

        <hr style={{ width: "90%" }} />

        <Grid
          container
          spacing={0}
          sx={{ margin: "1em auto 0 auto", width: "100%" }}
        >
          <Grid item xs={6}>
            <div
              style={{
                minWidth: higherTimeframeName ? "80%" : "100%",
                minHeight: "60px",
              }}
            >
              {higherNameEdit ? (
                <CustomMultiTextField
                  name="Higher Timeframe Field"
                  size="small"
                  rows={1}
                  variant="standard"
                  value={higherTimeframeName}
                  label={higherTimeframeName ? "" : "Enter Timeframe"}
                  onChange={(e) => {
                    setHigherNameEdit(true);
                    setHigherTimeframeName(e.target.value);
                  }}
                  fontSize="20px"
                  disableUnderline={true}
                />
              ) : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "200",
                    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    margin: "1em auto 0 auto",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  id="setHigherNameEditsetHigherTimeframeName"
                  {...bind()}
                >
                  {higherTimeframeName} Before
                </p>
              )}
            </div>

            {dailyLink ? (
              dailyLink.startsWith("https://www.tradingview.com") ? (
                <img
                  id="setDailyLink"
                  {...bindDeleteLinks()}
                  style={imgStyle}
                  src={dailyLink}
                  alt="Higher Screenshot Link"
                  height="auto"
                  onClick={() => openImageViewer(0)}
                />
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    color: "#f23645",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setDailyLink("");
                  }}
                >
                  Invalid Link
                </p>
              )
            ) : (
              <div
                style={{
                  minHeight: "60px",
                  margin: "0 auto",
                }}
              >
                <CustomMultiTextField
                  labelColor="#f23645"
                  size="small"
                  rows={1}
                  variant="standard"
                  value={dailyLink}
                  label="No Screenshot Detected"
                  onChange={(e) => {
                    setDailyLink(e.target.value);
                  }}
                  fontSize="16px"
                  disableUnderline={true}
                />
              </div>
            )}
            <div
              style={{
                width: dailyDescrtpiton ? "80%" : "100%",
                margin: "0 auto",
              }}
            >
              <CustomMultiTextField
                size="small"
                rows={6}
                variant="standard"
                value={dailyDescrtpiton}
                label={dailyDescrtpiton ? "" : "Enter Descrtpiton"}
                onChange={(e) => {
                  setDailyDescrtpiton(e.target.value);
                }}
                fontSize="16px"
                disableUnderline={true}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div
              style={{
                margin: "0 auto",
                minHeight: "60px",
              }}
            >
              {lowerNameEdit ? (
                <CustomMultiTextField
                  name="Lower Timeframe Field"
                  size="small"
                  rows={1}
                  variant="standard"
                  value={lowerTimeframeName}
                  label={lowerTimeframeName ? "" : "Enter Timeframe"}
                  onChange={(e) => {
                    setLowerTimeframeName(e.target.value);
                  }}
                  fontSize="20px"
                  disableUnderline={true}
                />
              ) : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "200",
                    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    margin: "1em auto 0 auto",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  id="setLowerNameEditsetLowerTimeframeName"
                  {...bind()}
                >
                  {lowerTimeframeName} Before
                </p>
              )}
            </div>
            {hourlyLink ? (
              hourlyLink.startsWith("https://www.tradingview.com") ? (
                <img
                  id="setHourlyLink"
                  {...bindDeleteLinks()}
                  style={imgStyle}
                  src={hourlyLink}
                  alt="Lower Screenshot Link"
                  height="auto"
                  onClick={() => openImageViewer(1)}
                />
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    color: "#f23645",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setHourlyLink("");
                  }}
                >
                  Invalid Link
                </p>
              )
            ) : (
              <div
                style={{
                  minHeight: "60px",
                  margin: "0 auto",
                }}
              >
                <CustomMultiTextField
                  labelColor="#f23645"
                  size="small"
                  rows={1}
                  variant="standard"
                  value={hourlyLink}
                  label="No Screenshot Detected"
                  onChange={(e) => {
                    setHourlyLink(e.target.value);
                  }}
                  fontSize="16px"
                  disableUnderline={true}
                />
              </div>
            )}

            <div
              style={{
                width: dailyDescrtpiton ? "80%" : "100%",
                margin: "0 auto",
              }}
            >
              {" "}
              <CustomMultiTextField
                size="small"
                rows={6}
                variant="standard"
                value={hourlyDescrtpiton}
                label={hourlyDescrtpiton ? "" : "Enter Descrtpiton"}
                onChange={(e) => {
                  setHourlyDescrtpiton(e.target.value);
                }}
                fontSize="16px"
                disableUnderline={true}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                margin: "0 auto",
                minHeight: "60px",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "200",
                  color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  textAlign: "center",
                  margin: "0 auto",
                }}
              >
                {higherTimeframeName} After
              </p>
            </div>

            {dailyLinkAfter ? (
              dailyLinkAfter.startsWith("https://www.tradingview.com") ? (
                <img
                  id="setDailyLinkAfter"
                  {...bindDeleteLinks()}
                  style={imgStyle}
                  src={dailyLinkAfter}
                  alt="Higher Screenshot Link"
                  height="auto"
                  onClick={() => openImageViewer(2)}
                />
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    color: "#f23645",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setDailyLinkAfter("");
                  }}
                >
                  Invalid Link
                </p>
              )
            ) : (
              <div
                style={{
                  margin: "0 auto",
                  minHeight: "60px",
                }}
              >
                <CustomMultiTextField
                  labelColor="#f23645"
                  size="small"
                  rows={1}
                  variant="standard"
                  value={dailyLinkAfter}
                  label="No Screenshot Detected"
                  onChange={(e) => {
                    setDailyLinkAfter(e.target.value);
                  }}
                  fontSize="16px"
                  disableUnderline={true}
                />
              </div>
            )}
            <div
              style={{
                width: dailyDescrtpitonAfter ? "80%" : "100%",
                margin: "0 auto",
              }}
            >
              <CustomMultiTextField
                size="small"
                rows={6}
                variant="standard"
                value={dailyDescrtpitonAfter}
                label={dailyDescrtpitonAfter ? "" : "Enter Descrtpiton"}
                onChange={(e) => {
                  setDailyDescrtpitonAfter(e.target.value);
                }}
                fontSize="16px"
                disableUnderline={true}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                margin: "0 auto",
                minHeight: "60px",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "200",
                  color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  textAlign: "center",
                  margin: "0 auto",
                }}
              >
                {lowerTimeframeName} After
              </p>
            </div>

            {hourlyLinkAfter ? (
              hourlyLinkAfter.startsWith("https://www.tradingview.com") ? (
                <img
                  id="setHourlyLinkAfter"
                  {...bindDeleteLinks()}
                  style={imgStyle}
                  src={hourlyLinkAfter}
                  alt="Lower Screenshot Link After"
                  height="auto"
                  onClick={() => openImageViewer(3)}
                />
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    color: "#f23645",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setHourlyLinkAfter("");
                  }}
                >
                  Invalid Link
                </p>
              )
            ) : (
              <div
                style={{
                  margin: "0 auto",
                  minHeight: "60px",
                }}
              >
                <CustomMultiTextField
                  labelColor="#f23645"
                  size="small"
                  rows={1}
                  variant="standard"
                  value={hourlyLinkAfter}
                  label="No Screenshot Detected"
                  onChange={(e) => {
                    setHourlyLinkAfter(e.target.value);
                  }}
                  fontSize="16px"
                  disableUnderline={true}
                />
              </div>
            )}
            <div
              style={{
                width: hourlyDescrtpitonAfter ? "80%" : "100%",
                margin: "0 auto",
              }}
            >
              <CustomMultiTextField
                size="small"
                rows={6}
                variant="standard"
                value={hourlyDescrtpitonAfter}
                label={hourlyDescrtpitonAfter ? "" : "Enter Descrtpiton"}
                onChange={(e) => {
                  setHourlyDescrtpitonAfter(e.target.value);
                }}
                fontSize="16px"
                disableUnderline={true}
              />
            </div>
          </Grid>
        </Grid>

        <span
          onClick={(e) => {
            auth.setTab(1);
            localStorage.setItem("trade-to-view", tradeId);
            localStorage.removeItem("forecast-to-view");
          }}
          style={{
            margin: "3em 0 1em 0",
            cursor: "pointer",
            fontSize: "16px",
            color: "#26a65d",
          }}
        >
          {tradeId ? (
            <span
              style={{
                color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                fontSize: "16px",
              }}
            >
              Linked Trade:{" "}
            </span>
          ) : null}

          {tradeId && tradeId.substring(0, 10)}
        </span>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "1em 0 1em 0",
          }}
        >
          <Tooltip title="Hold for 3 Seconds To Delete">
            <DeleteOutlinedIcon
              fontSize="large"
              id="deleteForecast"
              sx={{
                color: "#f23645",
                cursor: "pointer",
              }}
              {...bindDelete()}
              label={"Delete"}
            />
          </Tooltip>

          <Tooltip title="Close">
            <CloseIcon
              sx={{
                color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                cursor: "pointer",
                margin: "0 0.8em 0 0.8em",
              }}
              fontSize="large"
              onClick={() => goBack()}
            />
          </Tooltip>

          <Tooltip title="Save">
            <SaveIcon
              sx={{
                color: formSubmitted ? "lightGrey" : "#26a65d",
                cursor: "pointer",
              }}
              fontSize="large"
              onClick={() => {
                handleSave();
              }}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleForecasts;
