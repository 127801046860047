import { React, useContext } from "react";

import ResultsTracker from "../results-tracker";
import Calandar from "../calandar/index";

import TradingPlan from "../tradingPlan/index";

import AuthContext from "../utils/auth-context";
import useWindowDimensions from "../utils/width";

const PickScreen = (tab) => {
  const { width } = useWindowDimensions();

  switch (tab) {
    case 0:
      return width <= 899 ? (
        <div style={{ height: "500px" }}>
          <Calandar />
        </div>
      ) : (
        <Calandar />
      );
    case 1:
      return <ResultsTracker />;
    case 2:
      if (JSON.parse(localStorage.getItem("user")).role === "admin") {
        return <TradingPlan />;
      } else {
        return null;
      }
    default:
      return <></>;
  }
};

const Screens = () => {
  const auth = useContext(AuthContext);
  const tab = auth.tab;

  return PickScreen(tab);
};

export default Screens;
